import React, { Fragment, isValidElement, Element } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'
import { H2 } from 'components/common/Text/Text'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'
import EditExpander from './EditExpander'

const Drawer = ({
  id,
  isFilterGroup,
  dataAttribute,
  children,
  title,
  className,
  expanded,
  withHeading,
  handleToggleBlur,
  setToggleBlockRef,
  editExpander,
  seeEdit,
  complexTitle,
  hideBorder,
  setDrawerButtonRef,
  enhancedOnClick,
  toggleHandlers,
  drawerProps: {
    CustomDrawerTitle = null,
    CustomExpandIcon = undefined,
    customDrawerHandler = undefined,
    isActive = true,
    hideExpanderIcon = false,
    ...drawerProps
  } = {},
  ...props
}) => (
  <Wrapper
    id={ id }
    { ...dataAttribute }
    className={ className }
    ref={ setToggleBlockRef }
    hideBorder={ hideBorder && isActive && expanded }
    { ...props }
    { ...dynamicDataAttribute('drawer', toOneString(isValidElement(title) ? id : title)) }>
    {
      withHeading ?
        <H2 noMargin>
          <DrawerButton
            id={ id ? `${id}_drawer-button` : undefined }
            aria-expanded={ expanded }
            link
            data-drawer-title
            data-at-drawer-button
            complexTitle={ complexTitle }
            disabled={ !isActive }
            expanded={ expanded }
            seeEdit={ seeEdit }
            ref={ setDrawerButtonRef }
            onClick={ enhancedOnClick }
            onBlur={ handleToggleBlur }
            { ...props }>
            {
              CustomDrawerTitle ?
                <CustomDrawerTitle
                  { ...drawerProps }
                  expanded={ expanded }
                  isActive={ isActive }
                  seeEdit={ seeEdit } /> :
                title
            }
            {
              isActive && !hideExpanderIcon ?
                CustomExpandIcon ?
                  <CustomExpandIcon
                    alt=""
                    expanded={ expanded }
                    onClick={ customDrawerHandler } /> :
                  editExpander ?
                    <EditExpander data-drawer-icon expanded={ expanded } /> :
                    <ExpandIcon
                      alt=""
                      data-drawer-icon
                      noLazyLoad
                      asset={ props.accordion ? 'plus-red' : 'arrow-down-gray' }
                      alternative={ props.accordion ? 'minus-red' : 'arrow-up-gray' }
                      condition={ expanded }
                      { ...props } /> :
                null
            }
          </DrawerButton>
          {
            isActive && seeEdit &&
              <SeeEditButton link onClick={ customDrawerHandler }>
                <EditExpander data-drawer-icon />
              </SeeEditButton>
          }
        </H2> :
        <DrawerButton
          id={ id ? `${id}_drawer-button` : undefined }
          aria-expanded={ expanded }
          link
          data-drawer-title
          data-at-drawer-button
          disabled={ !isActive }
          expanded={ expanded }
          ref={ setDrawerButtonRef }
          onClick={ enhancedOnClick }
          onBlur={ handleToggleBlur }
          { ...props }>
          {
            CustomDrawerTitle ?
              <CustomDrawerTitle
                { ...drawerProps }
                expanded={ expanded }
                isActive={ isActive }
                seeEdit={ seeEdit } /> :
              <Fragment>
                { title }
                <ExpandIcon
                  alt=""
                  data-drawer-icon
                  noLazyLoad
                  asset={ props.accordion ? 'plus-red' : 'arrow-down-gray' }
                  alternative={ props.accordion ? 'minus-red' : 'arrow-up-gray' }
                  condition={ expanded }
                  { ...props } />
              </Fragment>
          }
          {
            isActive && !hideExpanderIcon ?
              CustomExpandIcon ?
                <CustomExpandIcon
                  alt=""
                  expanded={ expanded }
                  onClick={ customDrawerHandler } /> :
                editExpander ?
                  <EditExpander data-drawer-icon expanded={ expanded } /> :
                  <ExpandIcon
                    alt=""
                    data-drawer-icon
                    noLazyLoad
                    asset={ props.accordion ? 'plus-red' : 'arrow-down-gray' }
                    alternative={ props.accordion ? 'minus-red' : 'arrow-up-gray' }
                    condition={ expanded }
                    { ...props } /> :
              null
          }
        </DrawerButton>
    }
    {
      expanded && isActive &&
      <Content data-non-empty { ...props } isFilterGroup={ isFilterGroup } onBlur={ handleToggleBlur }>
        { children }
      </Content>
    }
  </Wrapper>
)

export default Drawer

Drawer.propTypes = {
  accordion: PropTypes.bool,
  afterClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  complexTitle: PropTypes.bool,
  dataAttribute: PropTypes.shape(),
  drawerProps: PropTypes.shape({
    customDrawerHandler: PropTypes.func,
    CustomDrawerTitle: PropTypes.func,
    CustomExpandIcon: PropTypes.func,
    hideExpanderIcon: PropTypes.bool,
    isActive: PropTypes.bool
  }),
  editExpander: PropTypes.bool,
  enhancedOnClick: PropTypes.func,
  expanded: PropTypes.bool,
  handleToggleBlur: PropTypes.func,
  hideBorder: PropTypes.bool,
  id: PropTypes.string,
  isFilterGroup: PropTypes.bool,
  seeEdit: PropTypes.bool,
  setDrawerButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  setToggleBlockRef: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  withHeading: PropTypes.bool
}

const Wrapper = styled.div`
  position: relative;
  word-wrap: break-word;
  border: 1px solid ${Theme.colors.light_grey};
  ${Mixin.responsive('padding', '21px 24px', '16px')}
  ${props => props.hideBorder && css`
    && { border-bottom: 0; }
  `}
  ${props => props.accordion && css`
    border-right: 0;
    border-left: 0;
    ${Mixin.responsive('padding', '20px 0', '20px 16px')}

    & + & {
      border-top: 0;
    }
  `}
  ${props => props.shade && css`
    background: ${Theme.colors.off_white};
    border: 1px solid ${Theme.colors.light_grey};
  `}
`

const DrawerButton = styled(Button)`
  && {
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 36px 0 0;
    display: block;
    width: 100%;
    text-align: left;
    color: ${Theme.colors.black};
    ${props => props.complexTitle && css`
      && {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0;
      }
    `}
    ${props => props.accordion && css`
      ${Mixin.font.Arial(16, 700)}
    `}
    ${props => props.seeEdit && css`
      ${Media.desktop`
        width: 92%;
      `}
    `}
  }
`

const Content = styled.div`
  margin-top: 20px;
  ${props => props.isFilterGroup && css`
    margin-top: 0;
  `}
  ${props => props.shade && css`
    margin-top: 20px;
  `}
  ${props => props.accordion && css`
    margin-top: 25px;
  `}
`

const ExpandIcon = styled(Icon)`
  position: absolute;
  right: 20px;
  width: 18px;
  ${Mixin.responsive('right', '20px', '16px')}
  ${Mixin.responsive('top', '20px', '15px')}
  ${props => props.accordion && css`
    width: 20px;
    ${Mixin.responsive('top', '19px', '20px')}
  `}
`

const SeeEditButton = styled(Button)`
  && {
    display: inline-block;
    position: relative;
    ${Mixin.responsive('bottom', '24px', '-5px')}
    padding: 0;
    margin-left: 5px;
    float: right;
  }
`
