import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import { ActionButton } from 'components/MyGarage2.0/components/SharedStyles'

const VehicleDeleteModal = ({ handleHide, handleVehicleDelete }) =>
  <Modal
    data-at-garage-deletevehiclemodal
    closeHandler={ handleHide }>
    <TitleContainer>
      <CustomIcon asset="delete-red" width={ 28 } height={ 34 } />
      <Title>DELETE VEHICLE</Title>
    </TitleContainer>
    <Description>
      This action will delete your vehicle from Big O Tires Account.
    </Description>
    <Description>Are you sure you want to delete your vehicle?</Description>
    <ButtonsWrapper>
      <ActionButton data-at-garage-deletevehiclemodal-delete onClick={ handleVehicleDelete }>
        DELETE VEHICLE
      </ActionButton>
      <CustomButton data-at-garage-deletevehiclemodal-cancel onClick={ handleHide }>
        Cancel
      </CustomButton>
    </ButtonsWrapper>
  </Modal>

VehicleDeleteModal.propTypes = {
  handleHide: PropTypes.func,
  handleVehicleDelete: PropTypes.func
}

export default VehicleDeleteModal

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`

const Title = styled.div`
  ${Mixin.font.Arial(20, 700, 24)};
  margin: 0;
  color: ${Theme.colors.black};
`

const CustomIcon = styled(Icon)`
  margin-right: 12px;
`

const Description = styled.div`
  text-align: center;
  ${Mixin.font.Arial(14, 400, 16)};
  margin: 0 0 30px 0;
  color: ${Theme.colors.black};
`

const ButtonsWrapper = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CustomButton = styled(ActionButton)`
  margin: 30px 0 !important;
  color: ${Theme.colors.red};
  background: ${Theme.colors.transparent};
  border: none;

  &:hover {
    background: ${Theme.colors.transparent};
    color: ${Theme.colors.red};
  }
`
