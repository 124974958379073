import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Link from 'components/common/Link/Link'
import { H3 } from 'components/common/Text/Text'
import { dynamicDataAttribute, injectProps, toOneString, setLinkDataAttrVal, setFooterApplyNowProp } from 'helpers/utils'
import { injectRelAttribute } from './helpers'
import { NavItem as WrapperNavItem } from '../FooterMainMenuContent'

const FooterMenuLink = ({
  placeholders: {
    links
  },
  link: {
    path
  } = {},
  displayName,
  onClick = () => {}
}) =>
  <NavItem key={ displayName } data-at-footer-navitem { ...dynamicDataAttribute('footer-menu-link', toOneString(displayName)) }>
    { displayName &&
      <TitleLink to={ path } data-at-title-link onClick={ onClick }><Title>{ displayName }</Title></TitleLink>
    }
    {
      React.Children.count(links) > 0 &&
        <List data-non-empty role="list">
          {
            React.Children.map(links, link =>
              <ListItem role="listitem" data-at-navlink>
                {
                  injectProps(link, {
                    ...dynamicDataAttribute('footer-list-item-link', setLinkDataAttrVal(link)),
                    ...setFooterApplyNowProp(link),
                    rel: injectRelAttribute(link)
                  })

                }
              </ListItem>
            )
          }
        </List>
    }
  </NavItem>

FooterMenuLink.propTypes = {
  displayName: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.string
  }),
  onClick: PropTypes.func,
  placeholders: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.node)
  })
}

export default FooterMenuLink

const NavItem = styled.div`
  ${Mixin.responsive('margin', '0 0 34px', '0 28px 0 0')};

  /* stylelint-disable-next-line */
  ${WrapperNavItem}:last-child & {
    margin-bottom: 0;
  }
`

const TitleLink = styled(Link)`
  color: ${Theme.colors.white};
`

const Title = styled(H3)`
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: ${Theme.colors.white};
  ${Mixin.responsive('margin', '0 0 15px', '0')};
  ${Media.mobile`
    padding: 18px 0;
    border-top: 1px solid ${Theme.colors.black};
  `}

  /* stylelint-disable-next-line */
  ${WrapperNavItem}:last-child & {
    ${Media.desktop`
      font-weight: 400;
    `}
  }
`

const List = styled.ul`
  padding: 0;
  margin: 0;
`

const ListItem = styled.li`
  margin-bottom: 10px;

  & ${Link} {
    font-size: 12px;
    color: ${Theme.colors.white};
  }

  :not(.blispay-generic) .epjs-body-action-link {
    text-decoration: none;
  }

  .epjs-body-action-link {
    font-size: 12px;
    color: ${Theme.colors.white};

    :hover {
      text-decoration: underline;
    }
  }
`
