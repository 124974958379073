import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'

const FooterMainMenuContentBlock = ({ placeholders: { footerMainContentBlocks = []} = {}, ...props }) =>
  <Wrapper data-background-src="//tbc.scene7.com/is/image/TBCCorporation/bot-footer-bg?wid=1084&qlt=50&op_blur=1">
    <InnerWrapper data-max-width role="navigation" aria-label="Secondary">
      { footerMainContentBlocks }
    </InnerWrapper>
  </Wrapper>

FooterMainMenuContentBlock.propTypes = {
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    footerContents: PropTypes.arrayOf(PropTypes.shape())
  })
}

export default FooterMainMenuContentBlock

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  background-image: url('//tbc.scene7.com/is/image/TBCCorporation/bot-footer-bg?wid=1084&qlt=50&op_blur=1');
  background-color: ${Theme.colors.black};
  background-size: cover;
  ${Mixin.responsive('padding', '0 50px', '16px 16px 0')}
`
const InnerWrapper = styled.div`
  display: table;
  width: 100%;
`
