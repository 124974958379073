const tireSkuRegex = /(\d)+(-BOT)/
export const validateAddToCartItem = skuId => tireSkuRegex.test(skuId)

export const createSkuIDList = (products = [], promotions = [], services = [], otdpAddons = []) =>
  [...products, ...promotions, ...services, ...otdpAddons]
    .map(({ skuId, promotionId, serviceId, partNo }) => skuId || promotionId || serviceId || partNo)
    .join()

/* eslint-disable camelcase */
export const formatFullStoryProductObj = ({
  id: product_id_str,
  category: category_str,
  name: name_str,
  brand: brand_str,
  variant: variant_str,
  price: price_real,
  dimension2: gbblabel_str
}) => ({
  product_id_str,
  category_str,
  name_str,
  brand_str,
  variant_str,
  price_real,
  gbblabel_str
})
/* eslint-enable camelcase */

export const reduceAdjustmentAmount = (acc, currVal) => acc + currVal

export const createAddToQuoteConversionData = (products, skuId) => {
  const filteredProducts = products.length && products.filter(({ skuId: productSkuId }) => productSkuId === skuId)
  const { rawTotalPrice = '', otdpAddons = [], promotions = []} = filteredProducts[0] || {}

  const totalOTDPPricing = otdpAddons.length &&
    (otdpAddons.map(({ price }) => price).reduce(reduceAdjustmentAmount) || 0)

  const filteredPromotions = (promotions.length && promotions.filter(({ promotionRebate }) => !promotionRebate)) || []
  const totalPromotionsDeduction = filteredPromotions.length &&
    (filteredPromotions.map(({ adjustmentAmount }) => adjustmentAmount).reduce(reduceAdjustmentAmount) || 0)

  const revenue = (rawTotalPrice + totalOTDPPricing) - totalPromotionsDeduction
  const skuIDList = createSkuIDList(filteredProducts, filteredPromotions, [], otdpAddons)

  return {
    revenue,
    skuIDList
  }
}

export const setSessionStorageItem = (item, value) =>
  sessionStorage && sessionStorage.setItem && sessionStorage.setItem(item, JSON.stringify(value))

export const storingItemsWithExpiry = (key, value) => {
  const now = new Date()
  const item = {
    value,
    expiry: now.getTime()
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const getItemsWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // checking whether diff btw current and expiry time is 4 hrs
  if ((now.getTime() - item.expiry) >= 4 * 60 * 60 * 1000) {
    // If the item is expired, delete the item from storage
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

