import { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'

const fontStyles = (size, weight, height) => css`
  ${size && css`
    font-size: ${size}px;
  `}
  ${weight && css`
    font-weight: ${weight};
  `}
  ${height && css`
    line-height: ${height}px;
  `}
`

// Open Sans Alt
const Arial = (size, weight, height) => css`
  ${fontStyles(size, weight, height)};
  font-family: ${Theme.fonts.Arial};
  font-stretch: normal;
  letter-spacing: 0.02em;
  transform: none;
`

// Teko Alt1
const ArialNarrow = (size, weight, height) => css`
  ${fontStyles(size, weight, height)};
  font-family: ${Theme.fonts.ArialNarrow};
  letter-spacing: 0.03em;
  font-stretch: condensed;
  transform: scale(1, 1.3);
`

// Teko Alt2
const ArialBlack = (size, weight, height) => css`
  ${fontStyles(size, weight, height)};
  font-family: ${Theme.fonts.ArialBlack};
  letter-spacing: 0.03em;
  font-stretch: condensed;
  transform: scale(1, 1.7);
`

const Factoria = (size, weight, height) => css`
  ${fontStyles(size, 400, height)};
  font-family: ${Theme.fonts.Factoria};
`

export default { Factoria, Arial, ArialBlack, ArialNarrow }
