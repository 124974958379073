import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from 'components/common/Text/Text'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import Link from 'components/common/Link/Link'
import Theme from 'components/common/Theme/Theme'
import {
  SHOP_NOW,
  TIRES_AVAILABLE
} from './constants'

const AvailableTires = React.memo(({
  componentType,
  vehicleDescriptionDynamicLeft,
  searchUrl,
  activeVehicleModelId,
  storeId,
  numberOfAvailableTires,
  getNumberOfTiresOptionsAvailable,
  tireSize
}) => {
  useEffect(() => {
    // api call to get the number of tires options available
    getNumberOfTiresOptionsAvailable(searchUrl)
  }, [activeVehicleModelId, storeId, tireSize])

  // returning null in case tire options are not available to stop the component from rendering.
  if (!numberOfAvailableTires) return null

  return (
    <AvailableLabel
      componentType={ componentType }
      vehicleDescriptionDynamicLeft={ vehicleDescriptionDynamicLeft }>
      <Text>{numberOfAvailableTires} {TIRES_AVAILABLE}</Text>
      <CustomLink to={ searchUrl } componentType={ componentType }>
        {SHOP_NOW} <ArrowIcon asset="caret-red-right" alt="Arrow" />
      </CustomLink>
    </AvailableLabel>
  )
})

AvailableTires.propTypes = {
  activeVehicleModelId: PropTypes.string.isRequired,
  componentType: PropTypes.string,
  getNumberOfTiresOptionsAvailable: PropTypes.func.isRequired,
  numberOfAvailableTires: PropTypes.number,
  searchUrl: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  tireSize: PropTypes.string.isRequired,
  vehicleDescriptionDynamicLeft: PropTypes.number
}

export default AvailableTires

const sharedStyle = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  ${Media.mediumMobile`
    ${props => props.componentType === 'carBar' && css`
      font-size: 12px;
    `}
  `}
  ${Media.desktop`
    font-size: 16px;
  `}
`
const ArrowIcon = styled(Icon)`
  display: inline-block;
  padding-bottom: 2px;
  width: 7px;
`
const CustomLink = styled(Link)`
  ${sharedStyle}
  padding-left: 12px;
  color: ${Theme.colors.red};
`
const AvailableLabel = styled.div`
  display: flex;
  ${props => props.componentType === 'my-garage' && css`
    margin: 0 auto;
  `}
  ${Media.tabletNarrow`
    ${props => props.componentType === 'carBar' && css`
      padding-left: 66px;
    `}
  `}
  & ${Text} {
    ${sharedStyle}
  }
`
