import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Button from 'components/common/Button/Button'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'

const RecordDeleteModal = ({ handleHide, handleRecordDelete }) =>
  <Modal
    data-at-garage-deletevehiclemodal
    closeHandler={ handleHide }>
    <TitleContainer>
      <CustomIcon asset="delete-red" width={ 28 } height={ 34 } />
      <Title>DELETE SERVICE RECORD</Title>
    </TitleContainer>
    <Description>
      This action will delete your service record.
    </Description>
    <Description>Are you sure you want to delete it?</Description>
    <ButtonsWrapper>
      <ActionButton data-at-garage-deletevehiclemodal-delete onClick={ handleRecordDelete }>
        DELETE SERVICE RECORD
      </ActionButton>
      <CustomButton data-at-garage-deletevehiclemodal-cancel onClick={ handleHide }>
        Cancel
      </CustomButton>
    </ButtonsWrapper>
  </Modal>

RecordDeleteModal.propTypes = {
  handleHide: PropTypes.func,
  handleRecordDelete: PropTypes.func
}

export default RecordDeleteModal

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`

const Title = styled.div`
  ${Mixin.font.Arial(20, 700, 24)};
  margin: 0;
  color: ${Theme.colors.black};
`

const Description = styled.div`
  text-align: center;
  ${Mixin.font.Arial(14, 400, 16)};
  margin: 0 0 30px 0;
  color: ${Theme.colors.black};
`

const ButtonsWrapper = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ActionButton = styled(Button)`
  cursor: pointer;
`

const CustomButton = styled(ActionButton)`
  margin: 30px 0 !important;
  color: ${Theme.colors.red};
  background: ${Theme.colors.transparent};
  border: none;

  &:hover {
    background: ${Theme.colors.transparent};
    color: ${Theme.colors.red};
  }
`

const CustomIcon = styled(Icon)`
  margin-right: 11px;
`
