import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ScrollLock from 'react-scrolllock'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import { H1 } from 'components/common/Text/Text'
import KeyPress from 'components/common/KeyPress/KeyPress'
import Portal from 'components/common/Portal/Portal'
import Button from 'components/common/Button/Button'
import FocusTrap from 'focus-trap-react'
import { getOnClickAndOnKeyPressDownEvents } from 'helpers/getOnClickAndOnKeyPressDownEvents'
import { MODAL_DIALOG_ID, MODAL_CONTENT_ID } from 'constant'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'
import { MODAL_BODY_CLASSNAME, MODAL_HEADER_CLASSNAME, MODAL_ID, MODAL_OVERLAY } from './constants'

const Modal = ({
  children,
  closeHandler,
  closeIconDataAttribute,
  title,
  modalName = '',
  className,
  zIndex,
  centered,
  setModalFocusRef,
  onFocus,
  modalScrollHeight,
  titleDataAttribute,
  modalHeight,
  modalWidth,
  hideScrollY,
  closeIcon = 'cross',
  dynamicTop,
  ...props
}) =>
  <Portal id={ MODAL_ID }>
    <KeyPress escape={ closeHandler }>
      <ModalWrapper zIndex={ zIndex } dynamicTop={ dynamicTop } { ...props }>
        <FocusWrapper id={ MODAL_OVERLAY }>
          <ModalContent
            className={ className }
            role="dialog"
            tabIndex="-1"
            aria-labelledby={ MODAL_DIALOG_ID }
            aria-modal="true"
            id={ MODAL_CONTENT_ID }
            ref={ setModalFocusRef }
            modalHeight={ modalHeight }
            modalWidth={ modalWidth }
            modalScrollHeight={ modalScrollHeight }
            { ...dynamicDataAttribute('modal', toOneString(modalName || title)) }
            onFocus={ onFocus }>
            <ModalHeader data-at-modal-header hideHeaderLine={ !title } className={ MODAL_HEADER_CLASSNAME }>
              <CloseButton
                aria-label={ `Close ${title || modalName} dialog` }
                link
                data-at-modal-close
                { ...closeIconDataAttribute }
                { ...getOnClickAndOnKeyPressDownEvents(closeHandler) } >
                <Icon alt="" asset={ closeIcon } />
              </CloseButton>
              <Icon data-printonly asset="logo" alt="BigO Tire" width={ 228 } height={ 27 } />
              <Title
                data-at-modal-title
                { ...titleDataAttribute }
                id={ MODAL_DIALOG_ID }
                centered={ centered }
                hidden={ !title }
                headline>{ title }
              </Title>
            </ModalHeader>
            <ModalBody hideScrollY={ hideScrollY } data-at-modal-body className={ MODAL_BODY_CLASSNAME }>
              { children }
            </ModalBody>
          </ModalContent>
        </FocusWrapper>
      </ModalWrapper>
    </KeyPress>
    <ScrollLock touchScrollTarget={ document.getElementById(MODAL_ID) } />
  </Portal>

Modal.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  closeHandler: PropTypes.func,
  closeIcon: PropTypes.string,
  closeIconDataAttribute: PropTypes.shape(),
  dynamicTop: PropTypes.number,
  hideScrollY: PropTypes.bool,
  modalHeight: PropTypes.string,
  modalName: PropTypes.string,
  modalScrollHeight: PropTypes.number,
  modalWidth: PropTypes.string,
  onFocus: PropTypes.func,
  setModalFocusRef: PropTypes.func,
  title: PropTypes.string,
  titleDataAttribute: PropTypes.shape(),
  zIndex: PropTypes.number
}

export default Modal

const modalPrintCss = css`
  ${Media.print`
    position: relative;
    box-shadow: none;
    transform: none;
    margin: 0;
    background-color: white;
    top: 0;
    left: 0;
  `}
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.zIndex || 2147483646};
  background: ${({ dynamicTop }) => !dynamicTop ? Theme.colors.transparent_overlay : 'none'};
  text-align: left;
  display: flex;
  justify-content: center;
  align-content: center;
  ${modalPrintCss}
`

const FocusWrapper = styled(FocusTrap)`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1200;
  box-shadow: 0 1px 4px ${Theme.colors.transparent_overlay_shadow};
  background-color: ${Theme.colors.white};
  overflow-x: hidden;
  ${props => props.modalScrollHeight && css`
    height: ${props.modalScrollHeight}px;
  `}
  ${Media.desktop`
    top: 50%;
    left: 50%;
    max-height: 80%;
    width: 37.857em;
    transform: translate(-50%, -50%);
    ${props => props.modalHeight && css`
      height: ${props.modalHeight}px;
    `}
    ${props => props.modalWidth && css`
      width: ${props.modalWidth}px;
    `}
  `}
  ${Media.mobile`
    height: 100%;
    width: 100%;
  `}

  && {
    overflow-y: hidden;
    padding: 0;
  }

  ${modalPrintCss}
`

const ModalHeader = styled.div`
  width: 100%;
  ${Mixin.responsive('min-height', '60px', '50px')}
  ${Mixin.responsive('padding-left', '15px', '10px')}
  z-index: 1;
  background-color: ${Theme.colors.white};
  border-bottom: 1px solid ${Theme.colors.grey};
  ${props => props.hideHeaderLine && css`
    border: 0;
  `}
`

const ModalBody = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: clip;
  ${Mixin.responsive('padding', '20px 15px', '15px 20px')}
  ${props => props.hideScrollY && css`
    overflow-y: hidden;
  `}
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  line-height: 12px;
  border-radius: 25px;

  && {
    padding: 15px;
  }

  &&:hover {
    background-color: ${Theme.colors.off_white};
  }

  &&:active {
    background-color: ${Theme.colors.active_light_blue};
  }

  ${Media.mobile`
    top: 5px;
    right: 5px;
  `}
  ${Media.print`
    display: none;
  `}
`

const Title = styled(H1)`
  ${Media.mobile`
    font-size: 20px;
  `}
  ${props => props.centered && css`
    text-align: center;
  `}
`
