import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'

const ButtonNoneStaggered = ({ tireFrontSize, ...props }) =>
  <CustomButton { ...props } full follow stacked data-at-button-non-staggered>
    { tireFrontSize }
  </CustomButton>

ButtonNoneStaggered.propTypes = {
  onClickHandler: PropTypes.func,
  tireFrontSize: PropTypes.string
}

export default ButtonNoneStaggered

const CustomButton = styled(Button)`
  min-width: auto;
  text-align: left;
  padding-left: 20px;
  ${Mixin.font.Factoria(18, 24)}
  transform: none;
`
