import React from 'react'
import { Form, Field } from 'redux-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import { Input } from 'components/common/FormElements/Input'
import FindServiceHistoryError from './components/FindServiceHistoryError'

const FindServiceHistoryForm = ({
  handleSubmit,
  viewModel,
  error,
  isVehicleAndDataMismatchError,
  normalizeValue,
  hideFindServiceHistoryModal,
  description
}) =>
  <Form noValidate onSubmit={ handleSubmit }>
    {
      error &&
        <FindServiceHistoryError
          error={ error }
          iaVehicleAndDataMismatchError={ isVehicleAndDataMismatchError }
          handleMyGarageClick={ hideFindServiceHistoryModal } />
    }
    <ModalDescription>
      { description }
    </ModalDescription>
    <InputBlock>
      <Wrapper>
        <InputPlateField { ...viewModel.license } component={ Input } />
        <StateDropdownWrapper data-at-findservicehistorymodal-state>
          <Field
            { ...viewModel.state }
            inlineBlock
            component={ Input }
            optionsMaxHeight={ 220 }
            normalize={ normalizeValue } />
        </StateDropdownWrapper>
      </Wrapper>
      <Separator>or</Separator>
      <InputVinField { ...viewModel.vin } component={ Input } full="mobile" />
    </InputBlock>
  </Form>

FindServiceHistoryForm.propTypes = {
  description: PropTypes.string,
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  hideFindServiceHistoryModal: PropTypes.func,
  isVehicleAndDataMismatchError: PropTypes.bool,
  normalizeValue: PropTypes.func,
  viewModel: PropTypes.shape({
    license: PropTypes.shape(),
    state: PropTypes.shape(),
    vin: PropTypes.shape()
  })
}

export default FindServiceHistoryForm

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`

const InputPlateField = styled(Field)`
  vertical-align: middle;
  ${Mixin.responsive('margin-right', '10px', '16px')}
  ${Mixin.responsive('width', '78%', 'calc(50% - 8px)')}

  [data-error-message] {
    ${Media.desktop`
      width: 240px;
    `}
  }
`

const ModalDescription = styled.div`
  margin: 0 0 16px;
  ${Mixin.font.Arial(14, 400, 16)};
  color: ${Theme.colors.black};
  text-align: start;
`

const StateDropdownWrapper = styled.div`
  display: inline-block;
  line-height: 14px;
  vertical-align: middle;
  ${Mixin.responsive('width ', '20%', 'calc(50% - 8px)')}
  padding-top: 1px;
`

const InputVinField = styled(Field)`
  vertical-align: middle;
  ${Media.desktop`
    width: 100%;
  `}
`

const Separator = styled.span`
  color: ${Theme.colors.dark_grey};
  vertical-align: middle;
  text-transform: uppercase;
  ${Mixin.font.Arial(14, 700)}
  ${Mixin.responsive('margin', '20px 0', '20px 0')}
  ${Media.mobile`
    display: block;
    text-align: center;
  `}
`

