import { createAction } from 'redux-actions'
import { rest, isApiError } from 'services'
import { refresh } from 'actions/router'
import { handleStoreChangeModalView } from './helpers'
import { setPilotStore } from 'actions/storeLocator'

export const fetchDataAction = createAction('MYLOCATION/FETCH_DATA')
export const makeThisMyLocationAction = createAction('MYLOCATION/MAKE_THIS_LOCATION_MINE')
export const fetchMyLocationAction = createAction('MYLOCATION/FETCH_MY_LOCATION')
export const setErrorAction = createAction('MYLOCATION/SET_ERROR')
export const resetErrorAction = createAction('MYLOCATION/RESET_ERROR')

const handleApiError = err => dispatch => {
  if (isApiError(err)) {
    dispatch(setErrorAction(err.status.description))
  }
}

export const getMyLocations = () => async dispatch => {
  dispatch(resetErrorAction())
  dispatch(fetchDataAction())
  try {
    // TODO: replace this with real API endpoint for getting my locations
    const data = await rest.api.getMyStore()
    dispatch(fetchMyLocationAction({ data }))
  } catch (err) {
    dispatch(handleApiError(err))
  }
}

export const setMyLocation = (storeNum, byQueryParamSetting, haveCurrentStore) => async dispatch => {
  dispatch(resetErrorAction())
  dispatch(fetchDataAction())
  try {
    const data = await rest.api.addStoreToProfile({ storeNum })
      .then((data) => {
        const isPilotStore = data && data.isPilotStore

        dispatch(setPilotStore(storeNum, isPilotStore))
      })

    dispatch(makeThisMyLocationAction({ data }))
    dispatch(refresh())
  } catch (err) {
    if (byQueryParamSetting) {
      handleStoreChangeModalView(err, haveCurrentStore, dispatch)
    }

    dispatch(handleApiError(err))
  }
}

export const getStoreToProfileByIp = () => async dispatch => {
  try {
    await rest.api.getStoreToProfileByIp()
  } catch (err) {
    dispatch(handleApiError(err))
  }
}
