import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import Tabs from './components/Tabs'
import NewYMME from '../NewYMME'
import NewLicensePlate from '../NewLicensePlate'
import NewTireSize from '../NewTireSize'
import { TAB_YMME_NAME, TAB_LICENSE_PLATE_NAME, TAB_TIRE_SIZE_NAME } from 'constant'

const NewShopTiresModal = ({
  tab,
  hover,
  onMouseEnterHandler,
  onMouseLeaveHandler,
  isOnlyVehicle,
  withShowConfirmTireSizeModal,
  source,
  promo,
  skipTireSizeExperience,
  closeModal,
  isOnlyTireSize,
  ...props
}) =>
  <ModalBox
    newShopTiresModal>
    <Header>
      <InnerWrapper>
        <Title>Shop by:</Title>
        <Tabs
          tab={ tab }
          isOnlyVehicle={ isOnlyVehicle }
          isOnlyTireSize={ isOnlyTireSize }
          { ...props } />
        <CloseIcon
          alt="close modal"
          tabIndex="0"
          asset={ hover ? 'cross-red' : 'cross-grey' }
          onMouseEnter={ onMouseEnterHandler }
          onMouseLeave={ onMouseLeaveHandler }
          onClick={ closeModal } />
      </InnerWrapper>
    </Header>
    <Content>
      { tab === TAB_YMME_NAME && !isOnlyTireSize &&
        <NewYMME
          withShowConfirmTireSizeModal={ withShowConfirmTireSizeModal }
          source={ source }
          promo={ promo }
          skipTireSizeExperience={ skipTireSizeExperience }
          isOnlyVehicle={ isOnlyVehicle }
          { ...props } />
      }
      { tab === TAB_LICENSE_PLATE_NAME && !isOnlyTireSize &&
        <NewLicensePlate
          withShowConfirmTireSizeModal={ withShowConfirmTireSizeModal }
          source={ source }
          promo={ promo }
          skipTireSizeExperience={ skipTireSizeExperience }
          isOnlyVehicle={ isOnlyVehicle }
          { ...props } />
      }
      { tab === TAB_TIRE_SIZE_NAME && !isOnlyVehicle && (
        <NewTireSize
          source={ source }
          { ...props } />
      )}
    </Content>
  </ModalBox>

export default NewShopTiresModal

NewShopTiresModal.propTypes = {
  closeModal: PropTypes.func,
  hover: PropTypes.bool,
  isOnlyTireSize: PropTypes.bool,
  isOnlyVehicle: PropTypes.bool,
  onMouseEnterHandler: PropTypes.func,
  onMouseLeaveHandler: PropTypes.func,
  promo: PropTypes.string,
  skipTireSizeExperience: PropTypes.bool,
  source: PropTypes.string,
  tab: PropTypes.string,
  withShowConfirmTireSizeModal: PropTypes.bool
}

export const ModalBox = styled(Modal)`
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  position: relative;

  & .modal-header {
    display: none;
  }

  & .modal-body {
    max-width: 1570px;
    width: 100%;
    padding: 0 225px 35px;
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    & .modal-body {
      padding: 0 50px 35px;
    }
  }

  @media (max-width: 700px) {
    width: 100%;
    height: 100%;

    & .modal-body {
      padding: 0 20px 35px;
    }
  }
`

const Header = styled.div`
  background-color: ${Theme.colors.white_smoke};
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 100;
  width: 100%;
  left: 0;
  padding: 5px 225px 0;

  @media (max-width: 1150px) {
    padding: 5px 150px 0;
  }

  @media (max-width: 1024px) {
    padding: 5px 50px 0;
  }

  @media (max-width: 800px) {
    padding: 5px 20px 0;
  }

  @media (max-width: 700px) {
    position: fixed;
    top: 0;
  }

  @media (max-width: 370px) {
    padding: 5px 10px 0;
  }
`

const InnerWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  color: #7f7f7f;
  ${Mixin.font.Arial(12, 400)};
  margin-right: 25px;

  @media (max-width: 370px) {
    margin-right: 10px;
  }
`

const CloseIcon = styled(Icon)`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: auto;

  @media (max-width: 330px) {
    margin-left: 7px;
  }
`

const Content = styled.div`
  margin-top: 75px;
  position: relative;

  @media (max-width: 700px) {
    margin-top: 0;
  }
`
