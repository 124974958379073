import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Media from 'components/common/Media'
import Link from 'components/common/Link/Link'
import * as UI from './SharedStyles'
import PromoBanner from './PromoBanner'
import { QUICK_APPOINTMENT_PAGE } from 'constant'
import { PROMO_TITLE_ID } from './constants'
import Promotions from 'components/PromotionResultList/PromotionsList/components'

const BOTPromotionDetails = ({
  promotionDetails: {
    promoImageContent,
    hasEligibleProducts,
    promotionDescription,
    termsAndConditions,
    promotionExpired,
    promotionTitle,
    promotionRebate,
    linkToRebateDoc,
    promotionId
  } = {},
  featurePromos = [],
  isModal,
  handleSeeEligibleTiresClick,
  regionNameLink,
  hasStore,
  handleFindStoreClick,
  handlePromotionClick,
  appointmentBtnTitle
}) =>
  <Fragment>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div>
      <PromoBanner
        promoImageContent={ promoImageContent || '' }
        promotionTitle={ promotionTitle } />
    </div>
    <StyledPromoTitle id={ PROMO_TITLE_ID }>{ promotionTitle }</StyledPromoTitle>
    <UI.Container>
      <UI.DescriptionWrapper>
        {
          hasEligibleProducts && !promotionExpired &&
          <UI.EligibleTiresButton
            data-at-promotion-details-link
            full="mobile"
            data-printless
            onClick={ handleSeeEligibleTiresClick }>See Eligible Tires
          </UI.EligibleTiresButton>
        }
        {
          promotionExpired &&
          <UI.ExpiredPromotionMessage>
            This promotion has expired.
            {
              promotionRebate && linkToRebateDoc &&
              <Fragment>
                &nbsp;You can still download the rebate form <Link
                  data-at-promotion-details-link
                  data-printless
                  underline
                  bolder
                  size="20"
                  to={ linkToRebateDoc }>Here
                </Link>.
              </Fragment>
            }
          </UI.ExpiredPromotionMessage>
        }
        {
          promotionDescription &&
          <UI.PromotionDescription data-at-promotion-description content={ promotionDescription } />
        }
        {
          !hasStore && !promotionExpired &&
          <UI.EligibleTiresLink
            data-at-find-a-store-link
            button
            full="mobile"
            data-printless
            onClick={ handleFindStoreClick }>Find A Store
          </UI.EligibleTiresLink>
        }
        {
          !hasEligibleProducts && !isModal && !promotionExpired &&
          <UI.EligibleTiresLink
            data-at-make-an-appointment-link
            button
            full="mobile"
            data-printless
            to={ `${QUICK_APPOINTMENT_PAGE}?promoId=${promotionId}` }>{ appointmentBtnTitle }
          </UI.EligibleTiresLink>
        }
        { !promotionExpired &&
        <Media.Mobile>
          <UI.HeadlineWrapper>
            <UI.Logo medium inline asset="location-red" alt="" />
            <UI.Headline data-at-appointment-headline headline inline>Present In Store</UI.Headline>
          </UI.HeadlineWrapper>
        </Media.Mobile>
        }
        {
          promotionRebate && linkToRebateDoc &&
          <UI.EligibleTiresLink
            data-at-promotion-details-link
            data-printless
            bolder
            block
            rel="noopener noreferrer"
            to={ linkToRebateDoc }>Print Rebate Form(s)
          </UI.EligibleTiresLink>
        }
        {
          regionNameLink &&
          <UI.EligibleTiresLink
            data-at-promotion-regionname-link
            underline
            bolder
            data-printless
            block
            to={ regionNameLink }>View All Regional Offers
          </UI.EligibleTiresLink>
        }
        {
          termsAndConditions &&
          <UI.TermsAndConditions data-at-promotion-termsandconditions>
            <UI.TermsTitle data-at-promotion-termsandconditions-title>Terms & Conditions</UI.TermsTitle>
            <UI.TermsAndConditionsFootnote id="footnote" content={ termsAndConditions } tabIndex="-1" />
          </UI.TermsAndConditions>
        }
      </UI.DescriptionWrapper>
      {
        featurePromos.length > 0 &&
        <UI.MorePromotionsWrapper>
          <UI.MorePromotionsTitle>
            More Deals
          </UI.MorePromotionsTitle>
          <Promotions
            homePageVersion={ false }
            promotionList={ featurePromos }
            handlePromotionClick={ handlePromotionClick } />
        </UI.MorePromotionsWrapper>
      }
    </UI.Container>
  </Fragment>

BOTPromotionDetails.propTypes = {
  appointmentBtnTitle: PropTypes.string,
  featurePromos: PropTypes.arrayOf(PropTypes.shape()),
  handleFindStoreClick: PropTypes.func,
  handlePromotionClick: PropTypes.func,
  handleSeeEligibleTiresClick: PropTypes.func,
  hasStore: PropTypes.bool,
  isModal: PropTypes.bool,
  promotionDetails: PropTypes.shape({
    promoImageContent: PropTypes.string,
    mobileImagePath: PropTypes.string,
    hasEligibleProducts: PropTypes.bool,
    eligibleProductLink: PropTypes.string,
    promotionExpired: PropTypes.bool,
    promotionTitle: PropTypes.string,
    promotionDescription: PropTypes.string,
    promotionRebate: PropTypes.bool,
    linkToRebateDoc: PropTypes.string,
    termsAndConditions: PropTypes.string,
    promotionId: PropTypes.string
  }),
  regionNameLink: PropTypes.string
}

export default BOTPromotionDetails

const StyledPromoTitle = styled(UI.PromoTitle)`
  padding-left: 0;
  text-align: center;
`

