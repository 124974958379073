import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Text from 'components/common/Text/Text'
import PhoneLink from 'components/common/PhoneLink'
import { formatPhoneNumber } from 'helpers/phone-number'

import {
  NEW_AVAILABILITY,
  NEW_PRICE,
  EACH,
  CALL_TEXT
} from './constants'
import TireAvailability from 'components/common/TireAvailability'

const Product = ({
  product: {
    brandImageUrl,
    skuImageUrl,
    description,
    productName,
    price,
    brandLineName,
    priceChanged,
    hasPriceChanged,
    hasAvailabilityChanged,
    availableAsOf,
    availableTomorrow,
    availableToday
  } = {},
  phoneNumber,
  ...props
}) => {
  const renderTireAvailability = availableAsOf !== ''
    ? <TireAvailability
      availability={{ availableAsOf, availableToday, availableTomorrow }}
      isChangeModal />
    : null

  const renderPrice = Number(price) > 0
    ? <React.Fragment>
      <Price>${price.toFixed(2)}</Price>
      <Price small>{EACH}</Price>
    </React.Fragment>
    : <>
      <CustomText>{CALL_TEXT}</CustomText>
      <Media.Desktop>
        <CustomText>{ formatPhoneNumber(phoneNumber) }</CustomText>
      </Media.Desktop>
      <Media.Mobile>
        <PhoneLinkText number={ formatPhoneNumber(phoneNumber) } />
      </Media.Mobile>
    </>

  return (
    <Wrapper { ...props } >
      <Image src={ skuImageUrl } alt={ productName } />
      {
        brandImageUrl &&
        <Brand src={ brandImageUrl } alt={ brandLineName } />
      }
      <Name>{ productName }</Name>
      <Size>{ description }</Size>
      <AvailabilityPrice>
        <>
          { (hasPriceChanged || priceChanged) && <Title>{NEW_PRICE}</Title>}
          {renderPrice}
        </>
        { hasAvailabilityChanged ? (
          <AvailabilityStatus>
            <Title
              availableAsOf={ availableAsOf }
              hasPriceChanged={ hasPriceChanged || priceChanged }>
              {NEW_AVAILABILITY}
            </Title>
            {renderTireAvailability}
          </AvailabilityStatus>
        ) : null
        }
      </AvailabilityPrice>
    </Wrapper>
  )
}

Product.propTypes = {
  availability: PropTypes.shape(),
  lg: PropTypes.bool,
  phoneNumber: PropTypes.string,
  product: PropTypes.shape()
}

export default Product

const AvailabilityPrice = styled.div`
  margin-top: 16px;
`
const Wrapper = styled.div`
  border-top: 1px solid ${Theme.colors.light_grey};
  ${Mixin.responsive('padding', ' 30px 0 30px 168px', '20px 0 20px 152px')}
  ${props => props.first && css`
    border-top: none;
    ${Mixin.responsive('padding', ' 5px 0 30px 168px', '0 0 20px 152px')}
  `}
  ${Mixin.clearFix()}
`
const Brand = styled.img`
  margin-top: 7px;
  max-width: 112px;
`
const Name = styled.div`
  text-align: left;
  ${Mixin.font.Arial(18, 600)}
`
const Image = styled.img`
  float: left;
  max-width: 144px;
  padding: 6px 20px;
  ${Media.mobile`
    max-width: 136px;
  `}
  ${Mixin.responsive('margin', '0 0 0 -168px', '0 16px 0 -152px')}
`
const Size = styled.div`
  margin: 0 0 11px;
  line-height: 19px;
  text-transform: uppercase;
`
const Title = styled.div`
  line-height: 24px;
  font-weight: 700;
  color: ${Theme.colors.bright_red};
  ${Media.mediumLargeDevices`
    float: right;
    ${props => props.availableAsOf === '' && !props.hasPriceChanged && css`
      float: none;
    `}
  `}
`
const Price = styled.span`
  color: ${Theme.colors.sub_grey};
  line-height: 24px;
  font-weight: 700;
  ${Media.mediumLargeDevices`
    font-size: 16px;
  `}
  ${props => props.small && css`
    margin-left: 5px;
    color: ${Theme.colors.medium_grey};
    font-size: 14px;
    font-weight: 600;
  `}
`
const AvailabilityStatus = styled.div`
  margin-top: 8px;
`
const sharedStyle = css`
  ${Media.mediumLargeDevices`
    font-size: 16px;
  `}
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.393px;
`
const CustomText = styled(Text)`
  ${sharedStyle}
  display: block;
`
const PhoneLinkText = styled(PhoneLink)`
  ${sharedStyle}
`
