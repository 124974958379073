import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Icon from 'components/common/Icon/Icon'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Circle from 'components/common/Circle/Circle'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'

const ActiveCartReminderModal = ({
  closeModalHandler,
  totalCount,
  proceedAppointment
}) =>
  <ModalBox closeHandler={ closeModalHandler }>
    <Content>
      <IconPlaceholder>
        <Icon asset="cart-black" height={ 65 } width={ 65 } />
        <CartCircle>{ totalCount }</CartCircle>
      </IconPlaceholder>
      <Title>Welcome back!</Title>
      <Description>Just a reminder that you left items in your cart.</Description>
      <CustomButton onClick={ proceedAppointment }>QUOTE & SCHEDULE</CustomButton>
    </Content>
    <Image alt="Welcome back!" src="https://tbc.scene7.com/is/image/TBCCorporation/bigo-tech-bay-engine-549x450?$Welcome%2DModal$" />
  </ModalBox>

export default ActiveCartReminderModal

ActiveCartReminderModal.propTypes = {
  closeModalHandler: PropTypes.func,
  proceedAppointment: PropTypes.func,
  totalCount: PropTypes.number
}

const ModalBox = styled(Modal)`
  max-width: 1098px;
  width: 90%;

  .modal-header {
    min-height: 0;
    height: 0;

    button {
      background-color: ${Theme.colors.light_grey_background};
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: ${Theme.colors.light_grey_background};
      }
    }
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
  }

  ${Media.mobile`
    height: auto;
    background-image: none;

    .modal-body {
      display: block;
    }
  `}

  @media (max-width: 600px) {
    width: 95%;
  }
`

const Image = styled.img`
  width: 50%;
  ${Media.mobile`
    display: none;
  `}
`

const Content = styled.div`
  text-align: center;
  width: 50%;
  padding: 40px 47px;
  ${Media.mobile`
    width: 100%;
  `}

  @media (max-width: 480px) {
    padding: 20px;
  }
`

const IconPlaceholder = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
`

const CartCircle = styled(Circle)`
  position: absolute;
  right: -10px;
  top: -15px;
  font-size: 22px;
`

const Title = styled.div`
  ${Mixin.font.Factoria(40, 40)}
  letter-spacing: 1px;
  color: ${Theme.colors.black};
  text-transform: uppercase;
  margin-bottom: 10px;
`

const Description = styled.div`
  ${Mixin.font.Arial(28, 400)};
  color: ${Theme.colors.black};
  width: 70%;
  margin: 0 auto 30px;

  @media (max-width: 480px) {
    width: 100%;
  }
`

const CustomButton = styled(Button)`
  border: none;
  transform: scale(1, 1.3);
  padding: 8px 30px;
  cursor: pointer;
  background-color: ${Theme.colors.light_green};

  &:hover {
    background-color: ${Theme.colors.light_green};
  }
`
