import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import { setRequiredAttrs } from '../../helpers'

export const InputComponent = ({
  form,
  error,
  type,
  label,
  className,
  disabled,
  mask,
  placeholder,
  isError,
  full = false,
  required,
  ...props
}) =>
  <StyledInputComponent
    { ...props }
    mask={ mask }
    type={ type }
    placeholder={ placeholder }
    id={ `${form}-${label}-${placeholder}` }
    disabled={ disabled }
    isError={ isError }
    { ...setRequiredAttrs(required) } />

InputComponent.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  form: PropTypes.string,
  full: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isError: PropTypes.bool,
  label: PropTypes.string,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string
}

const StyledInputComponent = styled(InputMask)`
  ${Mixin.font.Arial(14, 500)}
  width: 100%;
  height: 40px;
  padding-left: 10px;
  color: ${Theme.colors.black};
  background: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.grey};
  ${props => props.isError && css`
    border: 1px solid ${Theme.colors.bright_red};
  `}
  ${props => props.disabled && css`
    border: 1px solid ${Theme.colors.grey};
    background-color: ${Theme.colors.off_white};
  `}
  outline: none;

  :focus {
    border: 1px solid ${Theme.colors.transparent_light_blue};
    box-shadow: 0 0 2px ${Theme.colors.transparent_blue};
    outline: 0;
    ${props => props.isError && css`
      border: 1px solid ${Theme.colors.bright_red};
    `}
    ${props => props.bordered && css`
      border-bottom: 1px solid ${Theme.colors.light_grey};
    `}
  }
`

export const TextAreaComponent = styled(StyledInputComponent.withComponent('textarea'))`
  border-radius: 0;
  height: ${props => props.height || 'auto'};
  resize: ${props => props.resize || 'none'};
  padding: 10px;

  :-moz-ui-invalid {
    box-shadow: none;
  }
`
