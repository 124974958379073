import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'

const AddEditButtons = ({
  actionLinks: [{ displayName } = {}] = [],
  handleEdit
}) =>
  <Wrapper>
    <Button data-at-carbar-editgarage stacked full onClick={ handleEdit }>{ displayName }</Button>
  </Wrapper>

AddEditButtons.propTypes = {
  actionLinks: PropTypes.arrayOf(PropTypes.shape()),
  handleEdit: PropTypes.func
}

export default AddEditButtons

const Wrapper = styled.div`
  & ${Button} {
    ${Mixin.font.ArialNarrow(13, 600)}
    padding: 8px 5px;
    margin-top: 20px;
    ${Media.mobileNarrow`
      margin-top: 10px;
    `}
  }

  ${Media.desktop`
    display: table-cell;
    width: 163px;
    padding-left: 30px;
    vertical-align: middle;
  `}
  ${Media.mobile`
    margin-top: 20px;
  `}
  ${Media.mobileNarrow`
    display: flex;
    ${Button}:first-child {
      margin-right: 10px;
    }
  `}
`
