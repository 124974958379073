import { createAction } from 'redux-actions'
import { show, hide } from 'redux-modal'
import { push } from 'connected-react-router'
import { refresh } from 'actions/router'
import { change } from 'redux-form'
import { rest } from 'services'
import { activateMarchexScript } from 'helpers/custom-events'
import { setInitialSearchMethodAction, fetchLocationsByAddr } from 'components/StoreLocatorPage/actions'
import {
  SEARCH_BY_LOCATION,
  LOCATION_FINDER_FORM,
  LOCATION_FINDER_INPUT,
  STORE_LOCATOR_MODAL_PAGE,
  STORE_SELECTOR_FIELD_NAME,
  APPOINTMENT_FORM_NAME,
  QUOTE_SUMMARY_PAGE,
  QUICK_APPOINTMENT_PAGE
} from 'constant'
import { getLocationPathname } from 'selectors/getPageLocation'
import { storingItemsWithExpiry } from 'services/ThirdPartyMiddleware/helpers.js'
import { resetSkusAddedToCart } from 'components/common/hoc/withAddToQuote/actions.js'

export const storeLocatorModalInit = createAction('STORE_LOCATOR_MODAL/INIT_STORE_LOCATOR')
export const storeLocatorModalComplete = createAction('STORE_LOCATOR_MODAL/COMPLETE_STORE_LOCATOR')
export const storeLocatorModalReset = createAction('STORE_LOCATOR_MODAL/RESET_STORE_LOCATOR')
export const pilotStoreDataLoading = createAction('STORE_LOCATOR/IS_PILOT_STORE_DATA_LOADING')
export const setPilotStoreInfo = createAction('STORE_LOCATOR/SET_PILOT_STORE_INFO')

export const setPilotStore = (storeNumber, isPoiletStore = false) => (dispatch) => {
  if (!storeNumber) return null

  dispatch(setPilotStoreInfo(isPoiletStore))
  storingItemsWithExpiry('pilotStoreInfo', { storeNumber, isPilotStore: isPoiletStore })
}

export const submitSearchStoreFormAction =
  ({ [LOCATION_FINDER_INPUT]: searchMask, params = {}} = {}) => async dispatch => {
    activateMarchexScript()
    dispatch(setInitialSearchMethodAction(SEARCH_BY_LOCATION))
    await dispatch(fetchLocationsByAddr(searchMask, params))

    dispatch(show(STORE_LOCATOR_MODAL_PAGE,
      { isAppointments: true, fieldIsEmpty: !searchMask || !(searchMask.trim().length) }))
  }

export const selectMyStore = ({
  storeNumber,
  isCheckout,
  handleTAOSDateTimeUpdate,
  navigateToThreeStepper,
  externalIdLong,
  isPreExpanded
}) => (dispatch, getState) => rest.api.addStoreToProfile({ storeNum: storeNumber })
  .then((data) => {
    const isPilotStore = data && data.isPilotStore
    const pathname = getLocationPathname(getState())

    dispatch(setPilotStore(storeNumber, isPilotStore))
    dispatch(hide(STORE_LOCATOR_MODAL_PAGE))
    if (pathname === QUOTE_SUMMARY_PAGE) {
      dispatch(resetSkusAddedToCart())
    }
  })
  .then(dispatch(storeLocatorModalComplete()))
  .then(dispatch(change(
    APPOINTMENT_FORM_NAME,
    STORE_SELECTOR_FIELD_NAME,
    storeNumber
  )))
  .then(dispatch(change(
    LOCATION_FINDER_FORM,
    LOCATION_FINDER_INPUT,
    ''
  )))
  .then(() => {
    const preExpandedQueryKey = isPreExpanded && 'preExpandedService'

    if (isCheckout) dispatch(push(QUOTE_SUMMARY_PAGE))
    if (navigateToThreeStepper) {
      dispatch(push(externalIdLong ? `${QUICK_APPOINTMENT_PAGE}?${preExpandedQueryKey || 'preSelectedService'}=${externalIdLong}` : QUICK_APPOINTMENT_PAGE))
    } else {
      dispatch(refresh())
    }
  })
  .then(() => handleTAOSDateTimeUpdate && handleTAOSDateTimeUpdate())
  .catch(err => console.error(err))


export const completeStoreSelection = () => dispatch => {
  dispatch(hide(STORE_LOCATOR_MODAL_PAGE))
  dispatch(storeLocatorModalComplete())

  return Promise.resolve()
}

export const showStoreLocatorModal = (props) =>
  props && props.nativeEvent ? show(STORE_LOCATOR_MODAL_PAGE) : show(STORE_LOCATOR_MODAL_PAGE, props || {})

export const showStoreLocatorModalInAppointmentMode = (handleTAOSDateTimeUpdate) =>
  show(STORE_LOCATOR_MODAL_PAGE, { isAppointments: true, handleTAOSDateTimeUpdate })

export const showStoreLocatorModalWithRedirectAction = path => (dispatch, getState) => {
  const { myStore } = getState()

  return !myStore ? dispatch(show(STORE_LOCATOR_MODAL_PAGE)) : dispatch(push(path))
}

export const showStoreLocatorModal3StepperEnabled = (externalIdLong, isPreExpanded) =>
  show(STORE_LOCATOR_MODAL_PAGE, { isAppointments: true, navigateToThreeStepper: true, externalIdLong, isPreExpanded })
