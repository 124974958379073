import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import Popover from 'components/common/Popover'
import Datepicker from 'components/common/Datepicker'
import { DefaultPopoverInnerContent } from 'components/common/Popover/elements'
import TextInput from '../CommonTextInput'
import { generateId, setRequiredAttrs } from '../../helpers'
import { filterProps, dynamicDataAttribute, toOneString } from 'helpers/utils'

const PopoverInnerContent = styled(DefaultPopoverInnerContent)`
  width: auto;

  &::after {
    ${Media.desktop`
      ${props => props.popoverPosition.tickLeft && css`
        left: 45px;
      `}
      ${props => props.popoverPosition.tickRight && css`
        right: 26px;
      `}
    `}

    ${Media.mobile`
      ${props => props.popoverPosition.tickRight && css`
        right: 94px;
      `}
    `}
  }
`

const PopoverLink = React.forwardRef(({
  withCellFocusByClick,
  withCellFocusByKey,
  onClick,
  onKeyDown,
  withButtonRef,
  ...props
}, ref) =>
  <AlignedButton
    { ...props }
    ref={ withButtonRef(ref) }
    onClick={ withCellFocusByClick(onClick) }
    onKeyDown={ withCellFocusByKey(onKeyDown) }>
    <ResponsiveIcon asset="calendar" alt="" />
  </AlignedButton>
)

PopoverLink.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  ref: PropTypes.func,
  withButtonRef: PropTypes.func,
  withCellFocusByClick: PropTypes.func,
  withCellFocusByKey: PropTypes.func
}

const propsToRemove = [
  'setDate',
  'pickerRef',
  'focusRef',
  'inputRef',
  'cellRef',
  'buttonRef',
  'popupRef',
  'setFocusRef',
  'setInputRef',
  'setCellRef',
  'setButtonRef',
  'setActiveRef',
  'handleOpen',
  'handleCloseWithoutFocus',
  'handleClose',
  'handleEventWithFocus',
  'onDateSelect',
  'withButtonRef',
  'withCellFocusByClick',
  'withCellFocusByKey',
  'withButtonFocus',
  'handleCloseKeyDown',
  'popoverProps'
]

const CommonDatePicker = ({
  date,
  dataAtAttrs,
  onDateSelect,
  setInputRef,
  setCellRef,
  setActiveRef,
  setPickerRef,
  popoverProps,
  withButtonFocus,
  ...props
}) =>
  <DateInput
    { ...filterProps(props, propsToRemove) }
    className={ props.className }
    inputRef={ setInputRef }
    id={ generateId({ ...filterProps(props, propsToRemove) }) }
    { ...setRequiredAttrs(props.required) }
    { ...dynamicDataAttribute('datepicker-input', toOneString(props.label)) }>
    <Popover
      activeRef={ setActiveRef }
      components={{ PopoverInnerContent, PopoverLink }}
      innerProps={ popoverProps }>
      <Datepicker
        pickerRef={ setPickerRef }
        focusedCellRef={ setCellRef }
        currentDate={ date }
        dataAtAttrs={ dataAtAttrs }
        onDateSelect={ onDateSelect } />
    </Popover>
  </DateInput>

export default CommonDatePicker

CommonDatePicker.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  dataAtAttrs: PropTypes.shape(),
  date: PropTypes.shape(),
  label: PropTypes.string,
  onDateSelect: PropTypes.func,
  popoverProps: PropTypes.shape(),
  required: PropTypes.bool,
  setActiveRef: PropTypes.func,
  setCellRef: PropTypes.func,
  setInputRef: PropTypes.func,
  setPickerRef: PropTypes.func,
  value: PropTypes.string,
  withButtonFocus: PropTypes.func
}

const DateInput = styled(TextInput)`
  width: 100%;
  height: 40px;
  color: ${Theme.colors.black};
  background: ${Theme.colors.white};
  outline: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;

  &::-webkit-inner-spin-button {
    height: 100%;
  }

  border-radius: 0;
  display: inline-flex;
  justify-content: space-between;
  ${Media.mobile`
    display: inline-flex !important;
  `}

  & input {
    display: inline-flex;
  }
`

const AlignedButton = styled(Button)`
  transform: none;
  background: none;
  border: none;
  height: 40px;
  vertical-align: bottom;
  flex-shrink: 0;
  ${Media.mobile`
    padding: 0;
    margin-left: 20px;
    min-width: 120px;
    text-align: left;
  `}

  &:hover {
    background: none;
  }
`

const ResponsiveIcon = styled(Icon)`
  ${Media.mobile`
    height: 40px;
    width: 40px;
  `}
`
