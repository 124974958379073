import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Link from 'components/common/Link/Link'

/* eslint-disable */
const ShopByTireSizeLink = ({ name, example, handleShopByTireSizeClick }) =>
  <div>
    <Link data-at-shop-by-tire-size-link onClick={ handleShopByTireSizeClick }>{ name }</Link>
    { !!example && <Caption>{ example }</Caption> }
  </div>
/* eslint-enable */

ShopByTireSizeLink.propTypes = {
  example: PropTypes.string,
  handleShopByTireSizeClick: PropTypes.func,
  name: PropTypes.string
}

export default ShopByTireSizeLink

const Caption = styled.span`
  display: block;
  margin: -5px 0 15px;
  font-style: italic;
  line-height: 12px;
  color: ${Theme.colors.caption_grey};
  text-transform: initial;
  ${Mixin.font.Arial(12, 400)}
  ${Media.mobile` {
    margin-top: -18px;
  `}
`
