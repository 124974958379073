import React from 'react'
import styled, { css } from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Link from 'components/common/Link/Link'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import Button from 'components/common/Button/Button'
import { filterProps } from 'helpers/utils'

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 100;
  text-align: left;
  ${Media.mobile`
    display: block;
    width: 100%;
  `}
  ${props => props.active && css`
    z-index: 200;
  `}
`

export const ListWrapper = Wrapper.withComponent('li')

export const Title = styled(Button)`
  position: relative;
  display: inline-block;
  padding: 12px;
  background: ${Theme.colors.white};
  border: none;
  color: ${Theme.colors.black};
  text-transform: none;
  ${Mixin.font.Arial(12, 400)}
  ${Media.mobile`
    display: block;
    padding: 17px 0 14px;
    font-size: 14px;
    color: ${Theme.colors.semidark_grey};
    background: transparent;
    box-shadow: 0 0;
    width: 100%;
    text-align: left;

    & ${Icon} {
      :not([data-static]) {
        position: absolute;
        right: 22px;
      }
    }
    ${props => props.active && css`
      padding: 17px 0 0;
      font-weight: 600;
    `}
    ${props => props.bolder && css`
      font-weight: bold;
    `}
    ${props => props.addPaddingBottom && css`
      padding-bottom: 20px;
    `}

    &:hover, &:focus {
      background: none;
      color: ${Theme.colors.semidark_grey};
    }
  `}

  ${Media.desktop`
    z-index: 1;
    padding: 6px 12px;
    :not([data-noseparator])::before {
      position: relative;
      top: 2px;
      left: -12px;
      display: inline-block;
      height: 12px;
      border-left: 1px solid ${Theme.colors.light_grey};
      content: '';
    }

    &:hover,
    &:focus {
      background: ${Theme.colors.white};
      color: ${Theme.colors.black};
    }
  `}
  ${props => props.active && css`
    box-shadow: 0 2px 8px ${Theme.colors.transparent_grey_hover};

    ::before {
      visibility: hidden;
    }
  `}

  &:hover,
  &:focus {
    border: none;
  }
`

const notAllowedProps = ['desktopPadding']

export const TitleLink = styled(props => <Link { ...filterProps(props, notAllowedProps) } />)`
  display: inline-block;
  padding: 12px;
  color: ${Theme.colors.black};
  text-decoration: none;
  background: ${Theme.colors.white};
  ${Mixin.font.Arial(12, 400)}
  ${props => props.active && css`
    box-shadow: 0 2px 8px ${Theme.colors.transparent_grey_hover};
  `}
  ${Media.mobile`
    display: block;
    padding-left: 0;
    font-size: 14px;
    color: ${Theme.colors.semidark_grey};
    background: transparent;

    :hover {
      text-decoration: none;
    }
  `}
  ${Media.desktop`
    :not([data-noseparator])::before {
      position: relative;
      top: 2px;
      left: -12px;
      display: inline-block;
      height: 12px;
      border-left: 1px solid ${Theme.colors.light_grey};
      content: '';
    }

    ${props => props.desktopPadding && css`
      padding: ${props.desktopPadding};
    `}

    :hover {
      text-decoration: none;
    }
  `}
`

export const Content = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${Media.desktop`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 320px;
    margin-top: -2px;
    background: ${Theme.colors.white};
    box-shadow: 0 0 8px ${Theme.colors.transparent_grey_hover};
    border: 1px solid ${Theme.colors.off_white};
    ${props => props.width && css`
      width: ${props.width};
    `}
    ${props => props.right && css`
      right: 0;
      left: auto;
    `}

    &:after{
      content: '';
      position: absolute;
      background: white;
      height: 10px;
      top: 0;
      left: -1px;
      right: -1px;
      z-index: 1;
      border-left: 1px solid ${Theme.colors.off_white};
      border-right: 1px solid ${Theme.colors.off_white};
    }
  `}
`
