import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Select, { createFilter } from 'react-select'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import { ErrorMessage } from '../CommonTextInput'
import { noop } from 'helpers/utils'
import { setRequiredAttrs } from '../../helpers'
import { ReactSelectLabel } from './components'

// do not pass onBlur method from redux-form to <Select />
// onBlur will be triggered method from redux-form and the field value in redux state will be lost

const ReactSelectDropdownInput = ({
  noResultsText = 'No Results Found',
  isSearchable = true,
  forwardedRef = noop,
  inlineBlock,
  full,
  onBlur,
  width,
  disabled,
  options,
  isError,
  value,
  label,
  error,
  filterConfig,
  showClearIndicator = false,
  altArrow,
  required,
  openMenuOnFocus = true,
  menuIsOpen,
  inputId,
  handleOnMenuOpen,
  handleOnMenuClose,
  setDropdownWrapperRef,
  optionsMaxHeight,
  ...props
}) =>
  <StyledSelect
    inlineBlock={ inlineBlock }
    full={ full }
    width={ width }
    optionsMaxHeight={ optionsMaxHeight }
    showClearIndicator={ showClearIndicator }
    ref={ setDropdownWrapperRef }>
    <Select
      { ...props }
      inputId={ inputId }
      value={ value }
      ref={ forwardedRef }
      filterOption={ createFilter(filterConfig) }
      options={ options }
      placeholder={
        <ReactSelectLabel forId={ inputId }>{ label }</ReactSelectLabel>
      }
      isDisabled={ disabled }
      classNamePrefix="react-select"
      noResultsText={ noResultsText }
      isClearable={ isSearchable }
      isSearchable={ isSearchable }
      label={ label }
      openMenuOnFocus={ openMenuOnFocus }
      menuIsOpen={ menuIsOpen }
      onMenuOpen={ handleOnMenuOpen }
      onMenuClose={ handleOnMenuClose }
      { ...setRequiredAttrs(required) } />

    { isError &&
    <ErrorMessage
      data-at-dropdown-error-message
      htmlFor={ inputId }
      disabled={ disabled }
      static={ props.static }>{ error }</ErrorMessage> }
    { value &&
    <ReactSelectLabel
      aboveInput
      forId={ inputId }>{ label }</ReactSelectLabel>
    }
  </StyledSelect>

export default ReactSelectDropdownInput

ReactSelectDropdownInput.propTypes = {
  active: PropTypes.bool,
  altArrow: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  filterConfig: PropTypes.shape(),
  forwardedRef: PropTypes.func,
  full: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handleOnMenuClose: PropTypes.func,
  handleOnMenuOpen: PropTypes.func,
  inlineBlock: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inputId: PropTypes.string,
  isError: PropTypes.bool,
  isExplicitValue: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  noResultsText: PropTypes.string,
  onBlur: PropTypes.func,
  openMenuOnFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
  optionsMaxHeight: PropTypes.number,
  required: PropTypes.bool,
  setDropdownWrapperRef: PropTypes.func,
  showClearIndicator: PropTypes.bool,
  showSubmenu: PropTypes.bool,
  static: PropTypes.bool,
  value: PropTypes.shape(),
  width: PropTypes.string
}

const StyledSelect = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
  ${Mixin.property('full', `
    width: 100%;
  `)}

  ${Mixin.property('inlineBlock', `
    display: inline-block;
  `)}

  & .react-select__indicator-separator {
    display: none;
  }

  & .react-select__placeholder {
    color: ${Theme.colors.black};
    outline: none;
    ${Media.IE9`
      margin-top: 6px;
    `}
  }

  & .react-select--is-disabled .react-select__placeholder {
    color: ${Theme.colors.medium_grey};
  }

  & .react-select__dropdown-indicator {
    display: flex;
    padding: 8px;
    color: ${Theme.colors.black};
  }

  & .react-select__control--is-disabled .react-select__dropdown-indicator {
    color: ${Theme.colors.light_grey};
  }

  & .react-select__control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 2.85em;
    padding-left: ${props => props.textIndent || 10}px;
    color: ${Theme.colors.black};
    background: ${Theme.colors.white};
    border: 1px solid ${Theme.colors.grey};
    border-radius: 0;
    outline: none;
    ${Mixin.font.Arial(14, 500)}
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;

    :focus {
      border: 1px solid ${Theme.colors.transparent_light_blue};
      outline: 0;
      box-shadow: 0 0 2px ${Theme.colors.transparent_blue};
    }

    ${Media.IE9`
      display: table;
    `}
  }

  & .react-select__value-container {
    padding: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    display: flex;
    ${Media.IE9`
      display: table-cell;
    `}
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
  }

  & .react-select__indicators {
    ${Media.IE9`
      display: table-cell;
      text-align: right;
    `}
  }

  & .react-select__clear-indicator {
    ${props => !props.showClearIndicator && css`
      display: none;
    `}
  }

  & .react-select__single-value {
    font-weight: 600;
  }

  & .react-select__menu {
    max-height: ${props => props.optionsMaxHeight ? `${props.optionsMaxHeight}px` : 'auto'};
    border-radius: 0;
    outline: none;
    z-index: 2;
    padding: 0;
    margin: -1px 0 0;
    box-shadow: none;
    border: 1px solid ${Theme.colors.transparent_light_blue};
    border-top: 1px solid ${Theme.colors.light_grey};
  }

  & .react-select__option {
    white-space: nowrap;
  }

  & .react-select__option--is-selected {
    color: ${Theme.colors.black};
    background: none;
  }

  & .react-select__option--is-focused {
    background: ${Theme.colors.grey};
  }

  & .react-select__control--is-focused {
    box-shadow: none;
    border: 1px solid ${Theme.colors.transparent_light_blue};
  }

  & .react-select__control--is-focused .react-select__placeholder {
    position: absolute;
    top: -11px;
    left: 0;
    padding: 2px;
    font-size: 12px;
    color: ${Theme.colors.dark_grey};
    background-color: ${Theme.colors.white};
  }

  & .react-select--is-disabled .react-select__control {
    color: ${Theme.colors.light_grey};
    background: ${Theme.colors.off_white};
  }

  & .react-select__control--is-focused ~ div.react-select__menu {
    border: 1px solid ${Theme.colors.transparent_light_blue};
  }

  & .react-select__menu-list {
    padding: 0;
    max-height: ${props => props.optionsMaxHeight ? `${props.optionsMaxHeight}px` : 'auto'};
  }

  & .react-select__input input:required {
    box-shadow: none;
  }

  ${props => props.menuIsOpen && css`
    + label {
      display: none;
    }
  `}

  [aria-live] {
    display: none;
  }
`
