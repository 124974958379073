import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'
import { Form as ReduxForm, Field } from 'redux-form'
import { Input } from 'components/common/FormElements/Input'
import Button from 'components/common/Button/Button'
import Banner from 'components/common/Banner/Banner'
import Mixin from 'components/common/Mixin'
import withKeyPress from 'components/common/hoc/withKeyPress/withKeyPress'
import CheckValidationFormField from './CheckValidationFormField'

const { BOT_GOOGLE_RECAPTCHA_SITE_KEY } = (window.GLOBAL_VARS || process.env)

const LoginForm = ({
  form,
  error,
  captchaRef,
  isLoading,
  loginContent,
  setInnerRef,
  handleCustomSubmit,
  handleSubmit,
  setKeypressScopeRef
}) =>
  <Fragment>
    <Form
      data-at-loginform
      noValidate
      ref={ setKeypressScopeRef }
      onSubmit={ handleSubmit(handleCustomSubmit) }>
      <ErrorBanner
        tabIndex="-1"
        data-at-loginform-errorbanner
        setInnerRef={ setInnerRef }
        error_alternative
        hidden={ !error } >
        { error }
      </ErrorBanner>
      <InputCol>
        <Field
          { ...form.email }
          static
          full
          msHideCross
          component={ CheckValidationFormField } />
      </InputCol>
      <InputCol>
        <Field
          { ...form.password }
          static
          full
          component={ Input } />
      </InputCol>
      <InputCol>
        <Field { ...form.rememberMe } component={ Input } />
      </InputCol>
      <ReCAPTCHA
        ref={ captchaRef }
        sitekey={ BOT_GOOGLE_RECAPTCHA_SITE_KEY }
        size="invisible"
        badge="bottomleft" />
      <Button
        data-at-loginform-login
        full
        disabled={ isLoading }>
        { isLoading ? 'Loading...' : 'LOG IN' }
      </Button>
    </Form>
    { React.Children.map(loginContent,
      accountCredentialRecoveryLinks => React.cloneElement(accountCredentialRecoveryLinks))
    }
  </Fragment>

LoginForm.propTypes = {
  account: PropTypes.shape({
    isLocked: PropTypes.bool,
    locked: PropTypes.bool,
    description: PropTypes.string,
    captchaValue: PropTypes.string
  }),
  captchaRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) })
  ]),
  error: PropTypes.string,
  form: PropTypes.shape({
    email: PropTypes.shape(),
    password: PropTypes.shape(),
    rememberMe: PropTypes.shape()
  }),
  handleCaptchaChange: PropTypes.func,
  handleCustomSubmit: PropTypes.func,
  handleForgotPasswordClick: PropTypes.func,
  handleForgotUsernameClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  loginContent: PropTypes.arrayOf(PropTypes.node),
  setInnerRef: PropTypes.func,
  setKeypressScopeRef: PropTypes.func
}

export default withKeyPress(LoginForm)

const Form = styled(ReduxForm)`
  ${Mixin.responsive('margin-top', '0', '18px')}
`

const ErrorBanner = styled(Banner)`
  padding: 5px 30px 0;
`

const InputCol = styled.div`
  & + & {
    padding-top: 17px;
  }
`
