import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Modal from 'components/common/Modal'
import Button from 'components/common/Button/Button'
import Columns from 'components/common/Columns/Columns'
import ConfirmationModal from './components/ConfirmationModal'
import Vehicle from './components/Vehicle'

const VehicleMergeModal = ({
  userVehicle,
  userVehicleName,
  anonymousVehicle,
  handleModalClose,
  handleVehicleMerge,
  handleVehicleDuplicate
}) =>
  <ModalBox title="VERIFY VEHICLE" closeHandler={ handleModalClose }>

    <Instructions data-at-verifyvehicle-instructions>
      We found a similar vehicle in My Garage. Select the vehicle you want to shop
    </Instructions>

    <OptionColumns width="50% 0 50%" adaptive>
      <React.Fragment>
        <Vehicle
          type={ 'anonymous' }
          { ...anonymousVehicle }
          description="Would you like to merge your current vehicle with the similar vehicle in My Garage?" />
        <Button data-at-merge-vehicle-button full onClick={ handleVehicleMerge }>Merge</Button>
      </React.Fragment>
      <Separator title="Or" />
      <React.Fragment>
        <Vehicle
          type={ 'user' }
          { ...userVehicle }
          description="Would you like to add another similar vehicle in My Garage?" />
        <Button data-at-add-vehicle-button full onClick={ handleVehicleDuplicate }>Add</Button>
      </React.Fragment>
    </OptionColumns>

    <ConfirmationModal />
  </ModalBox>

VehicleMergeModal.propTypes = {
  anonymousVehicle: PropTypes.shape(),
  handleModalClose: PropTypes.func,
  handleVehicleDuplicate: PropTypes.func,
  handleVehicleMerge: PropTypes.func,
  userVehicle: PropTypes.shape(),
  userVehicleName: PropTypes.string,
  year: PropTypes.string
}

export default VehicleMergeModal


const ModalBox = styled(Modal)`
  overflow-y: auto;
  ${Mixin.responsive('padding', '25px 24px 30px', '60px 16px 25px')}
`

const OptionColumns = styled(Columns)`
  ${Media.desktop`
    [data-column]:first-child {
      padding-right: 30px;
      border-right: 1px solid ${Theme.colors.light_grey};
    }
    [data-column]:last-child {
      padding-left: 30px;
    }
  `}
`

const Separator = styled.div`
  position: relative;
  margin: 30px 0;
  border-top: 1px solid ${Theme.colors.light_grey};
  text-align: center;

  &::before {
    content: attr(title);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    padding: 0 10px;
    background-color: ${Theme.colors.white};
    ${Mixin.font.Arial(12, 500, 17)}
  }

  ${Mixin.mobileOnly()}
`

const Instructions = styled.div`
  padding-bottom: 30px;
  color: ${Theme.colors.dark_grey};
`
