import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Link from 'components/common/Link/Link'
import Button from 'components/common/Button/Button'
import PrintButton from 'components/common/PrintButton/PrintButton'
import HTMLContainer from 'components/common/HTMLContainer/HTMLContainer'
import RoundIcon from 'components/common/RoundIcon/RoundIcon'
import Text, { H1, H2 } from 'components/common/Text/Text'
import Theme from 'components/common/Theme/Theme'

export const PromoTitle = styled(H1)`
  text-transform: uppercase;
  ${Mixin.font.ArialNarrow(26, 600)}
  padding-left: 100px;
  ${Media.mobile`
    padding-left: 0;
    text-align: center;
    font-size: 20px;
  `}
`

export const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  ${Media.desktop`
    flex-direction: row;
  `}
  ${Media.mobile`
    flex-direction: column;
  `}
`

export const DescriptionWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  ${Mixin.responsive('padding', '30px 0 35px', '30px 16px 40px')}
`

export const EligibleTiresButton = styled(Button)`
  margin: 0 20px 30px 0;
`

export const EligibleTiresLink = EligibleTiresButton.withComponent(Link)

export const ExpiredPromotionMessage = styled.div`
  padding: 20px;
  border: 2px solid ${Theme.colors.red};
  color: ${Theme.colors.red};
  text-align: center;
  margin-bottom: 40px;
  ${Mixin.font.Arial(20, 600)}
`

export const PrintButtonWrapper = styled(PrintButton)`
  margin: 0 20px 30px 0;
`
export const TermsAndConditions = styled.div`
  font-style: italic;
  line-height: 18px;
  ${Mixin.font.Arial(12)}
`

export const TermsTitle = styled(H2)`
  ${Mixin.font.Arial(14, 600)}
`

export const PromotionDescription = styled(HTMLContainer)`
  padding-bottom: 40px;
  line-height: 1.5;
  font-size: 16px;
`

export const HeadlineWrapper = styled.div`
  text-align: center;
  ${Mixin.responsive('margin-bottom', '40px', '20px')}
`

export const Headline = styled(Text)`
  ${Mixin.responsive('line-height', '56px', '30px')}
  ${Media.mobile`
    font-size: 20px;
  `}
`

export const Logo = styled(RoundIcon)`
  ${Mixin.responsive('margin-right', '15px', '10px')}
  ${Mixin.responsive('vertical-align', '4px', '2px')}
`

export const TermsAndConditionsFootnote = styled(HTMLContainer)`
  font-size: 16px;
`

export const MorePromotionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${Media.desktop`
    margin-right: 20px;
    padding-left: 50px
  `}
  ${Media.mobile`
    padding: 0px 16px 40px;
  `}
`
export const MorePromotionsTitle = styled(H2)`
  text-transform: uppercase;
  margin: 0;
  ${Media.desktop`
    padding-left: 17px;
    font-size: 16px;
    font-weight: 600;
  `}
  ${Media.mobile`
    padding-left: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  `}
`
