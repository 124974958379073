import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'
import Banner from 'components/common/Banner/Banner'

export const Wrapper = styled.div`
  background: ${Theme.colors.white};
  z-index: 1;
  ${Media.desktop`
    position: absolute;
    top: 25px;
    right: 50px;
    width: 396px;
  `}
  ${Media.mobile`
    margin-top: -20px;
  `}
  box-shadow: 0 5px 5px ${Theme.colors.transparent_overlay};
`

export const InnerWrapper = styled.div`
  background: ${Theme.colors.white};
  ${Media.desktop`
    min-height: 450px;
  `}
  ${Mixin.responsive('padding', '0 20px 15px', '0 16px 20px')};

  [data-ymme-modal] & {
    padding: 0;
    ${Media.desktop`
      min-height: auto;
    `}
  }
`

export const Title = styled.div`
  padding-bottom: 20px;
  color: ${Theme.colors.black};
  ${Mixin.font.Arial(18, 500)}
`

export const FieldWrapper = styled.div`
  ${props => !props.noSpace && css`
    padding-bottom: 15px;
  `}
  ${props => props.inline && css`
    display: inline-block;
    width: 33%;
    padding-right: 5px;
    vertical-align: top;

    & + & {
      padding-left: 5px;
    }

    &:last-child {
      width: 34%;
      padding-right: 0;
    }
  `}
`

export const FieldLine = styled.div`
  padding: 10px 0;
`

export const FindButton = styled(Button)`
  margin-top: 20px;
  ${props => props.addMarginBottom && css`
    margin-bottom: 15px;
  `}
`

export const Hint = styled.div`
  padding: 15px 0 30px;
  color: ${Theme.colors.dark_grey};
  font-style: italic;
  ${Mixin.font.Arial(12)}
`

export const Instruction = styled.div`
  display: table;
  width: 100%;
  text-align: center;
  ${Mixin.responsive('padding-top', '45px', '20px')};
`

export const ErrorBanner = styled(Banner)`
  padding-top: 10px;
`
