import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { dynamicDataAttribute } from 'helpers/utils'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import { A } from 'components/common/Link/Link'
import { Vehicle, Trailer } from './components'
import { Tile, Circle } from './components/SharedStyles'

const VehicleTile = ({ isTrailer, isActiveVehicle, handleMakeVehicleActive, index, ...props }) =>
  <Tile isActiveVehicle={ isActiveVehicle } { ...dynamicDataAttribute('vehicle-tile', `garage-vehicle-${index}`) }>
    <Circle hidden={ !isActiveVehicle } asset="check-circle" width={ 16 } height={ 16 } data-at-active-circle />
    {
      isTrailer ?
        <Trailer
          { ...props }
          isActiveVehicle={ isActiveVehicle } /> :
        <Vehicle
          { ...props }
          isActiveVehicle={ isActiveVehicle } />
    }
    {
      isActiveVehicle ?
        <Selected data-at-selected-vehicle>Vehicle Selected</Selected> :
        <VehicleLink
          prevent
          data-at-use-vehicle-link
          handler={ handleMakeVehicleActive }>
          Use this Vehicle
        </VehicleLink>
    }
  </Tile>

VehicleTile.propTypes = {
  handleMakeVehicleActive: PropTypes.func,
  index: PropTypes.number,
  isActiveVehicle: PropTypes.bool,
  isTrailer: PropTypes.bool,
  masterVehicle: PropTypes.shape({
    yearDesc: PropTypes.string,
    year: PropTypes.string,
    makeDesc: PropTypes.string,
    modelDesc: PropTypes.string,
    subModelDesc: PropTypes.string
  }),
  vehicleImage: PropTypes.string
}

export default VehicleTile


const Selected = styled.div`
  margin-top: 10px;
  color: ${Theme.colors.green};
  text-transform: uppercase;
  ${Mixin.font.Arial(12, 700)}
`

const VehicleLink = styled(A)`
  display: block;
  margin-top: 10px;
  ${Mixin.font.Arial(12, 600)}
`
