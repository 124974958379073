import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Expander from 'components/common/Expander'
import Drawer from 'components/common/Drawer'
import { FieldLine, FindButton } from '../../../SharedStyles'
import { FIND_BUTTON_DEFAULT_TEXT } from './constant'
import TireSizeSelector from '../../../TireSizeSelector'

const CustomTireSize = ({
  className,
  customSizeButtonText,
  model,
  handlers,
  tireSize: {
    front,
    rear
  } = {},
  tireConfirmationContent: [
    {
      TireSelectorBlock: [
        {
          description = ''
        } = {}
      ] = []
    } = {}
  ] = [],
  handleRearTireSizeToggle,
  handleCustomTireSizeSearch,
  handleCloseDrawer
}) =>
  <TireSizeDrawer
    accordion
    className={ className }
    asset="plus-blue-drawer"
    alternative="minus-blue-drawer"
    title="My Tire Size Isn’t Listed"
    afterClose={ handleCloseDrawer }>
    <Description data-at-description>{ description }</Description>
    <FieldLine data-at-ymme-front>
      <TireSizeSelector data={ front } model={ model.front } handlers={ handlers.front } />
    </FieldLine>
    <Expander title="Add a Different Rear Tire Size" onClick={ handleRearTireSizeToggle }>
      <FieldLine data-at-ymme-rear>
        <TireSizeSelector data={ rear } model={ model.rear } handlers={ handlers.rear } />
      </FieldLine>
    </Expander>
    <FindButton
      data-at-custom-size-results-button
      full="mobile"
      important
      onClick={ handleCustomTireSizeSearch }>
      {
        customSizeButtonText || FIND_BUTTON_DEFAULT_TEXT
      }
    </FindButton>
  </TireSizeDrawer>

CustomTireSize.propTypes = {
  className: PropTypes.string,
  customSizeButtonText: PropTypes.string,
  handleCloseDrawer: PropTypes.func,
  handleCustomTireSizeSearch: PropTypes.func,
  handleRearTireSizeToggle: PropTypes.func,
  handlers: PropTypes.shape({
    rear: PropTypes.shape(),
    front: PropTypes.shape()
  }),
  model: PropTypes.shape({
    rear: PropTypes.shape(),
    front: PropTypes.shape()
  }),
  tireConfirmationContent: PropTypes.arrayOf(PropTypes.shape()),
  tireSize: PropTypes.shape()
}

export default CustomTireSize

const TireSizeDrawer = styled(Drawer)`
  padding: 16px 0;
  border-bottom: none;

  & [data-drawer-title] {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-transform: none;
  }

  & [data-drawer-icon] {
    top: 16px;
    right: 0;
  }
`

const Description = styled.div`
  padding-bottom: 10px;
`
