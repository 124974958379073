import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import toggle from 'components/common/hoc/toggle'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'

const Banner = ({ asset = 'alert', children, className, expanded, toggleHandlers, handler, setInnerRef, ...props }) =>
  <Wrapper className={ className } hidden={ expanded } ref={ setInnerRef } { ...props }>
    {
      !props.warning && !props.noicon &&
        <Icon data-icon asset={ asset } alternative="success" condition={ props.success } float="left" margin="2px 0 0 -30px" />
    }
    { children }
    {
      props.close &&
        <Icon
          data-close
          asset="cross"
          float="right"
          margin="0 -15px 0 0"
          onClick={ handler || toggleHandlers.onClick } />
    }
  </Wrapper>


Banner.propTypes = {
  asset: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  close: PropTypes.bool,
  expanded: PropTypes.bool,
  handler: PropTypes.func,
  noicon: PropTypes.bool,
  setInnerRef: PropTypes.func,
  success: PropTypes.bool,
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  warning: PropTypes.bool
}

export default toggle(Banner)

export const Wrapper = styled.div`
  position: relative;
  padding: 18px 30px 18px 50px;
  margin-bottom: 20px;
  color: ${Theme.colors.black};
  ${Mixin.font.Arial(14, 600)}
  ${Mixin.responsive('line-height', '22px', '17px')}
  ${props => props.success && css`
    background: ${Theme.colors.off_white};
    border: 1px solid ${Theme.colors.grey};
  `}
  ${props => props.error && css`
    background: ${Theme.colors.transparent_red};
    border: 1px solid ${Theme.colors.red};
  `}
  ${props => props.error_alternative && css`
    padding: 33px 30px 0;
    margin-bottom: 27px;
    line-height: 19px;
    color: ${Theme.colors.bright_red};
    background: none;
    border: none;
  `}
  ${props => props.warning && css`
    padding: 15px 30px 15px 15px;
    background: ${Theme.colors.pale_yellow};
  `}
  ${props => props.noicon && css`
    background: ${Theme.colors.off_white};
    border: 1px solid ${Theme.colors.grey};
    padding-left: 20px;

    & ${Icon} {
      display: none;
    }
  `}
`
