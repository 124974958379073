import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import { Item } from '../../../components/HeaderDropdown/HeaderDropdownMenu'
import { HOME } from 'constant'

const SignOutLink = ({ displayName, handleLogout, ...props }) =>
  <LogoutLink { ...props } to={ HOME } onClick={ handleLogout }>
    <LogoutIcon asset="icon-logout" />
    { displayName }
  </LogoutLink>

SignOutLink.propTypes = {
  displayName: PropTypes.string,
  handleLogout: PropTypes.func
}

export default SignOutLink

const LogoutIcon = styled(Icon)`
  position: absolute;
  ${Mixin.responsive('left', '12px', '17px')};
  ${Mixin.responsive('width', '14px', '16px')};
  ${Mixin.responsive('height', '14px', '16px')};
`

const LogoutLink = styled(Item)`
  :hover {
    & ${LogoutIcon} {
      left: 1px;
    }
  }
`
