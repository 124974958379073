import React from 'react'
import PropTypes from 'prop-types'
import TitleWrapper from '../NewTireSearch/components/TitleWrapper'
import TireSizeStepper from '../NewTireSearch/components/TireSizeStepper'
import Search from '../NewTireSearch/components/Search'
import OptionsList from '../NewTireSearch/components/OptionsList'
import { CustomUpdatingBlock } from '../NewTireSearch/SharedStyles'
import { OPTIONS_WRAPPER_CLASS_NAME, TIRE_SIZE_TITLE } from '../NewTireSearch/constant'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import MobileFixedWrapper from '../NewTireSearch/components/MobileFixedWrapper'
import Popover from './components/Popover'
import Button from 'components/common/Button/Button'
import { Separator } from 'components/common/Separator/Separator'

const NewTireSize = ({
  handleHide,
  step,
  endpointData = [],
  dataLoading,
  rearTireSizeChecked,
  toggleCheckbox,
  activePosition,
  stepNames,
  mostCommonEndpointData,
  mostCommonEndpointAllData,
  mostUncommonEndpointData,
  isShowAllSizes,
  showSizesButtonLabel,
  allSizesToggleHandler,
  isWidthStep,
  isFromTrailer,
  ...props
}) =>
  <>
    <MobileFixedWrapper place={ TIRE_SIZE_TITLE }>
      <TitleWrapper title={ TIRE_SIZE_TITLE } handleClose={ handleHide } />
      <StepperWrapper>
        <TireSizeStepper
          step={ step }
          stepNames={ stepNames }
          place={ TIRE_SIZE_TITLE }
          activePosition={ activePosition }
          rearTireSizeChecked={ rearTireSizeChecked }
          isFromTrailer={ isFromTrailer }
          { ...props } />
      </StepperWrapper>
      <HelpersWrapper>
        {
          !isFromTrailer && (
            <CheckboxWrapper>
              <CheckboxLabel htmlFor="rear-size-checkbox">
                <Checkbox
                  id="rear-size-checkbox"
                  checked={ rearTireSizeChecked }
                  type="checkbox"
                  onChange={ toggleCheckbox } />
                <LabelText>
                  Add rear tire size?
                </LabelText>
              </CheckboxLabel>
            </CheckboxWrapper>
          )
        }
        <Popover isFromTrailer={ isFromTrailer } />
      </HelpersWrapper>

      <Search
        stepNames={ stepNames }
        activePosition={ activePosition }
        step={ step }
        { ...props } />
    </MobileFixedWrapper>
    <OptionsWrapper className={ OPTIONS_WRAPPER_CLASS_NAME } condition={ dataLoading }>
      {
        Boolean(endpointData.length) && (!isWidthStep || isFromTrailer) && (
          <OptionsList
            stepNames={ stepNames }
            place={ TIRE_SIZE_TITLE }
            list={ endpointData }
            step={ step }
            activePosition={ activePosition }
            rearTireSizeChecked={ rearTireSizeChecked }
            { ...props } />
        )
      }
      {
        Boolean(endpointData.length) && isWidthStep && !isFromTrailer && (
          !isShowAllSizes
            ? (
              <OptionsList
                stepNames={ stepNames }
                place={ TIRE_SIZE_TITLE }
                list={ mostCommonEndpointData }
                step={ step }
                activePosition={ activePosition }
                rearTireSizeChecked={ rearTireSizeChecked }
                { ...props } />
            ) : (
              <>
                <OptionsList
                  stepNames={ stepNames }
                  place={ TIRE_SIZE_TITLE }
                  list={ mostCommonEndpointAllData }
                  step={ step }
                  activePosition={ activePosition }
                  rearTireSizeChecked={ rearTireSizeChecked }
                  { ...props } />

                <Separator margin="15px 0" />

                <OptionsList
                  stepNames={ stepNames }
                  place={ TIRE_SIZE_TITLE }
                  list={ mostUncommonEndpointData }
                  step={ step }
                  activePosition={ activePosition }
                  rearTireSizeChecked={ rearTireSizeChecked }
                  { ...props } />
              </>
            )
        )
      }
      {
        Boolean(endpointData.length) && isWidthStep && !isFromTrailer && (
          <Wrapper>
            <ShowAllSizesButton
              link
              onClick={ allSizesToggleHandler }>
              { showSizesButtonLabel }
            </ShowAllSizesButton>
          </Wrapper>
        )
      }
    </OptionsWrapper>
  </>

export default NewTireSize

NewTireSize.propTypes = {
  activePosition: PropTypes.string,
  allSizesToggleHandler: PropTypes.func,
  dataLoading: PropTypes.bool,
  endpointData: PropTypes.arrayOf(PropTypes.shape()),
  handleHide: PropTypes.func,
  isFromTrailer: PropTypes.bool,
  isShowAllSizes: PropTypes.bool,
  isWidthStep: PropTypes.bool,
  mostCommonEndpointAllData: PropTypes.oneOfType([
    PropTypes.arrayOf(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  mostCommonEndpointData: PropTypes.oneOfType([
    PropTypes.arrayOf(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  mostUncommonEndpointData: PropTypes.oneOfType([
    PropTypes.arrayOf(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  rearTireSizeChecked: PropTypes.bool,
  showSizesButtonLabel: PropTypes.string,
  step: PropTypes.number,
  stepNames: PropTypes.arrayOf(PropTypes.shape()),
  toggleCheckbox: PropTypes.func
}

const StepperWrapper = styled.div`
  display: flex;

  & > div:not(:first-child) {
    margin-left: 20px;
    width: 100%;
  }
`

const HelpersWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CheckboxWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 75px;

  @media (max-width: 1335px) {
    position: static;
    margin: 10px 0 20px 0;
  }
`

const CheckboxLabel = styled.label`
  display: flex;
`

const LabelText = styled.span`
  margin: 0 0 0 5px;
  text-decoration: underline;
`

const Checkbox = styled.input`
  width: 17px;
  height: 17px;
  /* stylelint-disable-next-line property-no-unknown */
  accent-color: ${Theme.colors.red};
`

const OptionsWrapper = styled(CustomUpdatingBlock)`
  padding: 0 150px 0 0;

  @media (max-width: 1335px) {
    position: static;
    padding: 0;
  }
`

const Wrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`

const ShowAllSizesButton = styled(Button)`
  cursor: pointer;
  font-weight: 400 !important;

  &:hover {
    text-decoration: none !important;
  }
`
