import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import { Input, inputTypes, InputComponent, InputLabel } from 'components/common/FormElements/Input'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'

const SearchModalInput = ({ handleSubmit, ...props }) =>
  <InputSearch
    { ...props }
    inputType={ inputTypes.TEXT_INPUT }>
    <SearchIcon data-at-search-icon asset="icon-search" onClick={ handleSubmit } />
  </InputSearch>

export default SearchModalInput

SearchModalInput.propTypes = {
  handleSubmit: PropTypes.func
}

const InputSearch = styled(Input)`
  width: 100%;

  & ${InputLabel} {
    top: -31px;
    right: 0;
    left: auto;
    color: ${Theme.colors.caption_grey};
    ${Mixin.font.Arial(12, 400)}
    ${Mixin.desktopOnly()}
  }

  & ${InputComponent} {
    caret-color: ${Theme.colors.grey};
    color: ${props => props.corrected ? Theme.colors.bright_red : Theme.colors.sub_grey};
    ${Mixin.font.Arial(24, 400)}
    ${Mixin.responsive('height', '80px', '60px')}
    ${Mixin.responsive('line-height', '80px', '25px')}
    ${Mixin.responsive('padding', '27px 70px 30px 20px', '17px 45px 20px 20px')}

    &::placeholder {
      color: ${Theme.colors.semidark_grey};
      ${Mixin.font.Arial(18, 400)}
    }

    ${Media.mobile`
      ${Mixin.font.Arial(18)}
    `}
  }
`

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  ${Media.mobile`
    max-width: 24px;
  `}
  ${Mixin.responsive('right', '25px', '10px')}
`
