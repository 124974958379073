import { connect } from 'react-redux'
import { lifecycle, compose, withProps } from 'recompose'
import isEqual from 'lodash/isEqual'
import withType from 'components/common/hoc/withType'
import { setStore } from './actions'
import { getPageLocation } from 'selectors'
import { googleAnalyticStoreNumberPush } from 'actions/googleAnalytic'
import MyStore from './MyStore'
import { FLEET_ELEMENT_ID } from '../HeaderFranchise'
import { SIGN_IN_ELEMENT_ID } from '../AccountAndSignIn/constants'
import { STORE_ELEMENT_ID } from './constants'
import { getQueryParamValue, isPageNotForProcessingByUrlList } from 'helpers/storeLocator'
import { STORE_NUMBER_QUERY_PARAM_NAME } from 'constant'
import { setMyLocation } from 'components/common/hoc/withMakeThisMyLocation/actions'
import { PAGES_LIST_THAT_EXCLUDED_FROM_PROCESSING } from 'components/common/hoc/withMakeThisMyLocation/constants'

const mapStateToProps = (state) => ({
  location: getPageLocation(state)
})

const mapDispatchToProps = {
  setStore,
  storeNumberPush: googleAnalyticStoreNumberPush,
  setMyLocation
}

export default compose(
  withType({ type: 'MyStore' }),
  withProps({
    nextItem: SIGN_IN_ELEMENT_ID,
    prevItem: FLEET_ELEMENT_ID,
    wrapperID: STORE_ELEMENT_ID
  }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { setStore, storeNumberPush, StoreDetails, setMyLocation } = this.props
      const currentStoreNumber = StoreDetails ? StoreDetails.store.storeNumber : 0
      const haveCurrentStore = Boolean(StoreDetails)
      const storeIdFromQueryParam = getQueryParamValue(STORE_NUMBER_QUERY_PARAM_NAME)
      const isPageNotForProcessing = isPageNotForProcessingByUrlList(PAGES_LIST_THAT_EXCLUDED_FROM_PROCESSING)

      if (!isPageNotForProcessing && storeIdFromQueryParam && (storeIdFromQueryParam !== currentStoreNumber)) {
        const byQueryParamSetting = true

        setMyLocation(storeIdFromQueryParam, byQueryParamSetting, haveCurrentStore)
      }

      setStore(StoreDetails)
      storeNumberPush(StoreDetails)
    },
    UNSAFE_componentWillReceiveProps({ setStore, storeNumberPush, StoreDetails, location }) {
      setStore(StoreDetails)

      const { StoreDetails: prevStoreDetails, location: prevLocation } = this.props

      if (!isEqual(StoreDetails, prevStoreDetails) || !isEqual(location, prevLocation)) {
        storeNumberPush(StoreDetails)
      }
    }
  })
)(MyStore)
