import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'
import { H2 } from 'components/common/Text/Text'
import Link from 'components/common/Link/Link'
import { SHOW_FAVORITES_NOTIFICATION } from './constants'
import { MY_FAVORITES_PAGE } from 'constant'

class BOTFavoritesNotification extends React.Component {
  constructor(props) {
    super(props)

    this.innerRef = React.createRef()
  }

  state = {
    type: '',
    item: {}
  }

  componentDidMount() {
    this.innerRef.current.style.animationDuration = '2s'
    this.innerRef.current.addEventListener &&
      this.innerRef.current.addEventListener('animationend', this.animationEndHandler)
    window && window.addEventListener && window.addEventListener(SHOW_FAVORITES_NOTIFICATION, this.eventHandler)
  }

  componentWillUnmount() {
    this.innerRef.current.removeEventListener &&
    this.innerRef.current.removeEventListener('animationend', this.animationEndHandler)
    window && window.removeEventListener && window.removeEventListener(SHOW_FAVORITES_NOTIFICATION, this.eventHandler)
  }

  eventHandler = ({ detail: { type, item }}) => {
    setTimeout(() => {
      this.setState({ item, type })
      this.innerRef.current.classList.add('fade-in-out')
    }, 1)
  }

  animationEndHandler = () => {
    this.innerRef.current.classList.remove('fade-in-out')
    this.setState({ item: {}, type: '' })
  }

  render() {
    const { type, item: { tireLineName, imageUrl, brandLogoUrl, frontTireSize, rearTireSize } = {}} = this.state

    return (
      <Wrapper ref={ this.innerRef } className={ type !== 'added' ? 'removed' : '' } frontAndRear={ !!rearTireSize } >
        <TitleWrapper role="status" aria-live="polite">
          {
            Boolean(type) && type !== 'added' && <Title>Removed from favorites </Title>
          }
          {
            type === 'added' && <>
              <Circle asset="check-circle" width={ 12 } height={ 12 } aria-hidden="true" />
              <Title>Added to favorites</Title>
            </>
          }
        </TitleWrapper>
        <InnerContainer className="inner-container">
          <Tire
            src={ imageUrl }
            alt={ tireLineName } />
          <ProductDetails>
            <div>
              <Logo
                src={ brandLogoUrl }
                alt="" />
            </div>
            <TireLineName noMargin bolder>
              { tireLineName }
            </TireLineName>
            <SizeWrapper>
              {
                frontTireSize &&
                  <>
                    <TireTitle>{ rearTireSize ? 'Front' : '' }</TireTitle>
                    <Size>{ frontTireSize }</Size>
                  </>
              }
              {
                rearTireSize &&
                <>
                  <TireTitle>Rear</TireTitle>
                  <Size>{ rearTireSize }</Size>
                </>
              }
            </SizeWrapper>
          </ProductDetails>
        </InnerContainer>
        <CustomLink to={ MY_FAVORITES_PAGE } hollow_button>VIEW FAVORITES</CustomLink>
      </Wrapper>
    )
  }
}

export default BOTFavoritesNotification

const fadeInAndOut = keyframes`
  0% { height: 0; }
  20% { height: 40%; }
  40% { height: 75%; }
  45% { height: 100%; }
  50% { height: 100%; }
  55% { height: 100%; }
  60% { height: 75%; }
  80% { height: 40%; }
  99% { height: 0; }

  100% {
    display: none;
    visibility: hidden;
    height: 0;
  }
`

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 0;
  max-height: 195px;
  ${props => props.frontAndRear && css`
    max-height: 245px;
  `}
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(144, 144, 144, 0.35);
  background: ${Theme.colors.white};
  z-index: 1001;
  ${Media.desktop`
    top: 0;
    right: 150px;
    min-width: 225px;
  `}
  ${Media.mobile`
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
    border-top: 0;
  `}

  &.fade-in-out {
    animation: ${fadeInAndOut} forwards;
    ${Media.mobile`
      border-top: 2px solid ${Theme.colors.red};
    `}
  }

  &.removed {
    && .inner-container {
      opacity: 0.5;
    }
  }
`

const InnerContainer = styled.div`
  display: flex;
  padding: 12px 8px 14px 0;
  margin: 0 10px;
`

export const Tire = styled.img`
  object-fit: contain;
  height: 80px;
  width: 57px;
  ${Mixin.responsive('margin', '0 auto', '0 10px')}
`

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  ${Mixin.responsive('margin-left', '15px', '0')}
`

export const Logo = styled.img`
  max-width: 200px;
  height: auto;
  width: 93px;
  cursor: pointer;
`

export const TireLineName = styled(H2)`
  display: inline-block;
  line-height: 21px;
  ${Mixin.font.Arial(13, 600)}
`

export const SizeWrapper = styled.div`
  display: block;
`

const TireTitle = styled.div`
  line-height: 21px;
  text-align: left;
  text-transform: uppercase;
  ${Mixin.font.Arial(13, 400)}
`

const Size = styled.div`
  line-height: 21px;
  text-transform: uppercase;
  text-align: left;
  color: ${Theme.colors.black};
  ${Mixin.font.Arial(13, 400)}
`
/**
 * FRE-185 styled(Link) was causing the issue of circular imports
 * bcoz of which while writing test cases an issue occured
 * cannot create styled-component for component: undefined.
 * so to resolve this issue need to replace it with
 * styled(props => <Link { ...props } />)
 */
const CustomLink = styled(props => <Link { ...props } />)`
  width: 89%;
  margin: 0 auto;
`

const TitleWrapper = styled.div`
  margin: 22px 0 0 13px;
`

const Circle = styled(Icon)`
  margin-right: 5px;
`
const Title = styled.span`
  ${Mixin.font.Arial(14, 600)}
`
