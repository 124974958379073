import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Item } from './components/UtilityMenuStyles'

const HeaderMobileUtilityMenu = ({ mobileMainMenuExpanded, placeholders: { headerContent = []} = {}}) =>
  <Menu hidden={ mobileMainMenuExpanded }>
    {
      React.Children.map(headerContent, content =>
        <Item>{ content }</Item>)
    }
  </Menu>

HeaderMobileUtilityMenu.propTypes = {
  mobileMainMenuExpanded: PropTypes.bool,
  placeholders: PropTypes.shape({
    headerContent: PropTypes.array
  })
}

HeaderMobileUtilityMenu.type = 'HeaderMobileUtilityMenu'
HeaderMobileUtilityMenu.placeholders = ['headerContent']

export default HeaderMobileUtilityMenu
