import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'
import Mixin from 'components/common/Mixin'
import { MAKE_AN_APPOINTMENT_BUTTON_ID } from 'constant'
import MenuItemWrapper from '../MenuItemWrapper'

const MakeAnAppointmentWrapper = ({
  name,
  mobileMainMenuExpanded,
  mobileMainMenuToggleHandlers,
  active,
  setInnerRef,
  stopPropagation,
  toggleHandlers,
  placeholders: { menus = []} = {},
  setFocusRef
}) =>
  <React.Fragment>
    <Media.Desktop>
      <MenuWrapper active={ active } ref={ setInnerRef } data-at-header-makeappointment>
        <ApptButton id={ MAKE_AN_APPOINTMENT_BUTTON_ID } ref={ setFocusRef } menu { ...toggleHandlers }>
          <Icon alt="" asset="calendar" margin="-5px 10px 0 0" />
          <Title>{ name }</Title>
        </ApptButton>
        <MyStoreWrapper hidden={ !active } { ...stopPropagation }>
          { menus }
        </MyStoreWrapper>
      </MenuWrapper>
    </Media.Desktop>
    <Media.Mobile>
      <MenuItemWrapper
        mobileMainMenuExpanded={ mobileMainMenuExpanded }
        mobileMainMenuToggleHandlers={ mobileMainMenuToggleHandlers }
        links={ menus }
        name={ name } />
    </Media.Mobile>
  </React.Fragment>

MakeAnAppointmentWrapper.propTypes = {
  active: PropTypes.bool,
  mobileMainMenuExpanded: PropTypes.bool,
  mobileMainMenuToggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.node)
  }),
  setFocusRef: PropTypes.func,
  setInnerRef: PropTypes.func,
  stopPropagation: PropTypes.shape({
    onClick: PropTypes.func
  }),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

export default MakeAnAppointmentWrapper


const MenuWrapper = styled.div`
  position: relative;
  z-index: 20;
  margin-right: 7px;
  top: -4px;
  ${props => props.active && css`
    box-shadow: 0 0 8px ${Theme.colors.transparent_grey_hover};

    ::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
      background: ${Theme.colors.white};
      z-index: 10;
    }
  `}
`

const ApptButton = styled(Button)`
  padding: 12px 18px 5px;
  position: relative;
  z-index: 20;
  border: none;
  background: ${Theme.colors.white};
  color: ${Theme.colors.red};
  text-transform: uppercase;
  transform: none;

  :hover {
    text-decoration: none;
    color: ${Theme.colors.red};
    background: ${Theme.colors.white};
  }
`

const Title = styled.span`
  display: inline-block;
  ${Mixin.font.ArialNarrow(16, 600)}
`

const MyStoreWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  width: auto;
  padding: 20px 0;
  margin-top: -1px;
  background: ${Theme.colors.white};
  box-shadow: 0 0 10px ${Theme.colors.transparent_grey_hover};
`
