import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import Circle from 'components/common/Circle/Circle'
import Media from 'components/common/Media'
import HeaderDropdownItem from '../components/HeaderDropdown/HeaderDropDownItem'
import { CART_ELEMENT_ID } from './constants'
import { SIGN_IN_ELEMENT_ID } from '../AccountAndSignIn/constants'
import { HEADER_FAVORITES_ELEMENT_ID } from 'components/MyFavorites/constants'

const HeaderCart = ({
  quoteURL,
  quoteTotalCount,
  favoritesTotalCount,
  favoritesUrl,
  expanded = true,
  ...props
}) =>
  <React.Fragment>
    <Media.Desktop>
      <HeaderDropdownItem
        id={ HEADER_FAVORITES_ELEMENT_ID }
        prevItem={ SIGN_IN_ELEMENT_ID }
        data-at-header-favorites
        { ...props }
        name={ `(${favoritesTotalCount || 0})` }
        link={{ path: favoritesUrl }}
        icon={ 'heart-black' }
        ariaLabel={ `${favoritesTotalCount} ${favoritesTotalCount === 1 ? 'item' : 'items'} in favorites` } />
      <HeaderDropdownItem
        id={ CART_ELEMENT_ID }
        prevItem={ HEADER_FAVORITES_ELEMENT_ID }
        data-at-header-cart
        noSeparator
        { ...props }
        name={ `(${quoteTotalCount || 0})` }
        quoteTotalCount={ quoteTotalCount }
        link={{ path: quoteURL }}
        icon={ 'cart' }
        desktopPadding={ '12px 12px 12px 0px' }
        ariaLabel={ `${quoteTotalCount} ${quoteTotalCount === 1 ? 'item' : 'items'} in cart` } />
    </Media.Desktop>
    <Media.Mobile>
      <CartPlaceholder data-at-header-favorites expanded={ expanded } padding={ '20px 0px 19px 20px' }>
        {
          <CartCircle>{ favoritesTotalCount }</CartCircle>
        }
        <Link
          to={ favoritesUrl }
          data-at-favorites-placeholder-link
          aria-label={ `${favoritesTotalCount} ${favoritesTotalCount === 1 ? 'item' : 'items'} in favorites` }>
          <Icon asset="heart-black" height={ 22 } width={ 25 } />
        </Link>
      </CartPlaceholder>
      <CartPlaceholder data-at-header-cart expanded={ expanded } padding={ '20px 20px 19px 20px' }>
        {
          <CartCircle>{ quoteTotalCount }</CartCircle>
        }
        <Link
          to={ quoteURL }
          data-at-cart-placeholder-link
          aria-label={ `${quoteTotalCount} ${quoteTotalCount === 1 ? 'item' : 'items'} in cart` }>
          <Icon asset="cart-mobile" height={ 20 } width={ 20 } />
        </Link>
      </CartPlaceholder>
    </Media.Mobile>
  </React.Fragment>

HeaderCart.propTypes = {
  expanded: PropTypes.bool,
  favoritesTotalCount: PropTypes.number,
  favoritesUrl: PropTypes.string,
  name: PropTypes.string,
  quoteTotalCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  quoteURL: PropTypes.string
}

export default HeaderCart

const CartPlaceholder = styled.div`
  position: relative;
  display: table-cell;
  padding: ${props => props.padding || 0};
  vertical-align: middle;
  ${props => props.expanded && css`
    background-color: ${Theme.colors.wildsand};
  `}
`

const CartCircle = styled(Circle)`
  position: absolute;
  left: 35px;
  top: 14px;
`
