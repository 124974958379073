import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Button from 'components/common/Button/Button'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import checkedBg from 'components/common/Icon/assets/Checked-green.png'
import crossBlue from 'components/common/Icon/assets/Cross-blue.svg'
import crossWhite from 'components/common/Icon/assets/Cross-white.svg'

const RecordDeleteModal = ({ handleHide }) =>
  <CustomModal>
    <Title>Fleet Certified</Title>

    <LogoIcon asset="fleet-certified-icon-gold" alt="Fleet Service Icon" />

    <Text>
      Offers Preferred Fleet Services:
    </Text>

    <List>
      <ListItem>Tire Installation, Repair, Rotation & Balance</ListItem>
      <ListItem>Complete Brake Service and Repair</ListItem>
      <ListItem>Complete Suspension Service and Repair</ListItem>
      <ListItem>Lube Oil and Filter Changes</ListItem>
    </List>

    <CustomButton onClick={ handleHide }>
      <ButtonText>
        Close
      </ButtonText>
    </CustomButton>
  </CustomModal>

RecordDeleteModal.propTypes = {
  handleHide: PropTypes.func
}

export default RecordDeleteModal

const CustomModal = styled(Modal)`
  & .modal-body {
    position: relative;
    padding: 20px 30px 25px;

    @media (max-width: 600px) {
      padding: 25px 30px 25px;
    }
  }

  & .modal-header {
    display: none;
  }
`

const LogoIcon = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 88px;
  height: 88px;

  @media (max-width: 600px) {
    right: 14px;
    top: 7px;
    width: 64px;
    height: 64px;
  }
`

const Title = styled.div`
  ${Mixin.font.Arial(20, 700, 24)};
  margin: 0 0 20px 0;
  color: ${Theme.colors.black};

  @media (max-width: 600px) {
    margin: 0 0 10px 0;
  }
`

const Text = styled.div`
  ${Mixin.font.Arial(16, 400, 18)};
  margin: 0 0 25px 0;
  color: ${Theme.colors.black};
`

const List = styled.ul`
  margin: 0 0 40px 0;
  padding: 0 0 0 30px;
`

const ListItem = styled.li`
  position: relative;
  margin-bottom: 7px;
  font-size: 16px;

  &::before {
    display: block;
    content: url(${checkedBg});
    width: 10px;
    height: 10px;
    position: absolute;
    left: -27px;
  }
`

const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 9px 30px 9px;
  background: ${Theme.colors.transparent};
  border: 1px solid ${Theme.colors.blue};
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: ${Theme.colors.red};
    border-color: ${Theme.colors.red};

    & p {
      color: ${Theme.colors.white};
    }

    & p::before {
      content: url(${crossWhite});
    }
  }
`

const ButtonText = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  color: ${Theme.colors.blue};
  text-transform: uppercase;
  font-size: 16px;
  line-height: 17px;

  &::before {
    position: absolute;
    left: -20px;
    top: 1px;
    width: 15px;
    content: url(${crossBlue});
  }
`
