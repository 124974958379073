import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const HeaderMainMenu = ({
  mobileMainMenuExpanded,
  mobileMainMenuToggleHandlers,
  placeholders: { links = []} = {}, ...props
}) =>
  <Ul id="menu-primary-items" data-at-header-menu>
    {
      React.Children.map(links, (link, index) =>
        React.cloneElement(link, {
          mobileMainMenuExpanded,
          mobileMainMenuToggleHandlers,
          index
        })
      )
    }
  </Ul>

HeaderMainMenu.propTypes = {
  '@type': PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  mobileMainMenuExpanded: PropTypes.bool,
  mobileMainMenuToggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    links: PropTypes.array
  })
}

HeaderMainMenu.type = 'HeaderMainMenu'
HeaderMainMenu.placeholders = ['links']

export default HeaderMainMenu

const Ul = styled.ul`
  margin: 0;
  padding: 0;
`
