import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Text from 'components/common/Text/Text'
import { dynamicDataAttribute } from 'helpers/utils'


const TeaserContent = ({ title, name, number }) =>
  <ListItem>
    <Icon>
      <Index>{ number }</Index>
    </Icon>
    <Text inline { ...dynamicDataAttribute(`teaser-item-${number}`) }>
      { title || name }
    </Text>
  </ListItem>

TeaserContent.propTypes = {
  name: PropTypes.string,
  number: PropTypes.number,
  title: PropTypes.string
}

TeaserContent.type = 'TeaserContent'

export default TeaserContent

const ListItem = styled.li`
  padding: 14px 0 6px 34px;
  line-height: 20px;
  list-style-type: none;
  ${Mixin.clearFix()}
  ${Media.mobileNarrow`
    padding-top: 8px;
  `}
`

const Icon = styled.span`
  display: inline-block;
  float: left;
  width: 25px;
  height: 25px;
  margin: -2px 0 0 -34px;
  text-align: center;
  border: 1px solid ${Theme.colors.red};
  border-radius: 50%;
`

const Index = styled.span`
  vertical-align: middle;
  color: ${Theme.colors.red};
  ${Mixin.font.ArialNarrow(16, 600)}
`
