import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'

// TODO generalize desktop and mobile styles

const HeaderSearch = ({ mobileMainMenuExpanded, showModal, ...props }) =>
  <div>
    <Media.Desktop>
      <SearchButton data-at-header-search onClick={ showModal }>
        <SearchIcon asset="search" height={ 18 } />
      </SearchButton>
    </Media.Desktop>

    <Media.Mobile>
      <FormMobile hidden={ mobileMainMenuExpanded } data-at-header-search-mobile onClick={ showModal }>
        <InputMobile disabled type="text" placeholder="Search" data-at-input />
        <ButtonMobile data-at-button>
          <Icon asset="search" width={ 20 } height={ 20 } />
        </ButtonMobile>
      </FormMobile>
    </Media.Mobile>
  </div>

HeaderSearch.propTypes = {
  '@type': PropTypes.string,
  autoSuggestEnabled: PropTypes.bool,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  mobileMainMenuExpanded: PropTypes.bool,
  name: PropTypes.string,
  searchText: PropTypes.string,
  showModal: PropTypes.func,
  templateIds: PropTypes.arrayOf(PropTypes.any)
}

export default HeaderSearch

const SearchIcon = styled(Icon)`
  margin-right: 5px;
`

const SearchButton = styled(Button)`
  width: 38px;
  height: 47px;
  padding: 0;
  margin: -5px -3px 0 3px;
  background: none;
  border: 0;
  transform: none;

  :hover {
    background: ${Theme.colors.white};
  }
`

const FormMobile = styled.div`
  position: relative;
  padding: 15px 15px 15px 16px;
`

const InputMobile = styled.input`
  width: 100%;
  padding: 11px 20px 11px 10px;
  color: ${Theme.colors.dark_grey};
  background-color: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.grey};
  outline: none;
  ${Mixin.font.Arial(14, 400)}
`

const ButtonMobile = styled.button`
  position: absolute;
  top: 16px;
  right: 18px;
  padding: 10px;
  background: none;
  border: 0;

  img {
    width: 20px;
    height: 20px;
  }
`
