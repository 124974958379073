import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/common/Icon/Icon'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'
import * as DD from '../HeaderDropdown'
import * as Menu from '../HeaderDropdownMenu'
import { BREAD_LOCATION_HEADER, BREAD_PLACEMENT_ID_HEADER } from 'constant'

const MenuWrapper = ({
  links = [],
  link: { path } = {},
  icon,
  name,
  nextItem,
  prevItem,
  active,
  combinedRefSetter,
  toggleHandlers,
  isInnerFocusedHovered,
  isSubmenu,
  focusBlurHoverHandlers,
  combinedHandlers,
  addPaddingBottom,
  stopPropagation,
  noSeparator,
  desktopPadding,
  ariaLabel,
  ...props
}) => {
  const content =
    <DD.Content role="menu" key="dd-content" hidden={ !active }>
      {
        links.map(({ link: { path = '' } = {}, name: linkName = '', '@type': type }, index) =>
          <li role="none" key={ index } { ...focusBlurHoverHandlers } { ...stopPropagation }>
            {
              type !== 'BOTApplyCreditCard' ?
                <Menu.Item
                  role="menuitem"
                  data-at-headerdropdown-item={ index }
                  to={ path }
                  { ...dynamicDataAttribute('menu-item', toOneString(linkName)) }>
                  { linkName }
                </Menu.Item>
                :
                <Menu.ApplyNowItem
                  locationType={ BREAD_LOCATION_HEADER }
                  placementId={ BREAD_PLACEMENT_ID_HEADER } />
            }
          </li>)
      }
    </DD.Content>

  /* eslint-disable */
  const title =
    <DD.Title
      key="dd-title"
      active={ active }
      role="menuitem"
      aria-expanded={ active ? 'true' : 'false' }
      aria-haspopup="true"
      data-at-headerdropdown-title
      data-at-dropdown-focus
      data-at-dropdown-title
      addPaddingBottom={ addPaddingBottom && active }
      { ...combinedHandlers }>
      { icon && <Icon noLazyLoad asset={ icon } alt="" width="10" height="14" margin="-2px 7px 0 0" /> }
      { name }
      <Icon
        alt=""
        asset="arrow-icon-full-black-down"
        alternative="arrow-icon-full-black-up"
        condition={ active }
        width="7"
        height="4"
        margin="0 0 0 7px"
        desktopOnly
        data-at-toggle-arrow />
      <Icon
        alt=""
        asset="arrow-right"
        width={ 10 }
        hidden={ active }
        mobileOnly
        data-at-toggle-arrow-mobile />
    </DD.Title>
  /* eslint-enable */
  const Wrapper = links.length > 0 && !icon ? DD.ListWrapper : DD.Wrapper
  return (
    <Wrapper
      role="none"
      active={ active }
      ref={ combinedRefSetter }
      { ...props }
      { ...dynamicDataAttribute('header-dropdown', toOneString(name)) }>
      { links.length > 0 ?
        <React.Fragment>
          { title }
          { content }
        </React.Fragment>
        : <DD.TitleLink
          to={ path }
          data-at-dropdown-title-link
          data-at-dropdown-focus
          desktopPadding={ desktopPadding }
          data-noseparator={ noSeparator }
          aria-label={ ariaLabel }>
          { icon && <Icon noLazyLoad asset={ icon } alt="" width="14" height="14" margin="-2px 7px 0 0" /> }
          { name }
        </DD.TitleLink>
      }
    </Wrapper>
  )
}


MenuWrapper.propTypes = {
  active: PropTypes.bool,
  addPaddingBottom: PropTypes.bool,
  ariaLabel: PropTypes.string,
  combinedHandlers: PropTypes.shape(),
  combinedRefSetter: PropTypes.func,
  desktopPadding: PropTypes.string,
  focusBlurHoverHandlers: PropTypes.shape(),
  icon: PropTypes.string,
  isInnerFocusedHovered: PropTypes.bool,
  isSubmenu: PropTypes.bool,
  link: PropTypes.shape({
    path: PropTypes.string
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string
    })
  ),
  name: PropTypes.string,
  nextItem: PropTypes.string,
  noSeparator: PropTypes.bool,
  prevItem: PropTypes.string,
  stopPropagation: PropTypes.shape({
    onClick: PropTypes.func
  }),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

export default MenuWrapper
