import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import Modal from 'components/common/Modal'
import { Input } from 'components/common/FormElements/Input'
import { Field } from 'redux-form'
import { addCommas } from 'components/MyGarage2.0/helpers'
import { integersOnly } from 'components/MyGarage2.0/components/VehiclePersonalizationModal/components/VehicleDetails/helpers'
import {
  ActionButton,
  MileageIcon,
  MileageEntity
} from 'components/MyGarage2.0/components/SharedStyles'
import { MILEAGE_METRIC } from 'constant'
import { MILEAGE_WARNING_NOTIFICATION } from '../../constants'

const EditMileageModal = ({
  handleHide,
  handleSubmit,
  model,
  onChange,
  showMileageCompareNotification
}) =>
  <CustomModal data-at-edit-mileage closeHandler={ handleHide }>
    <TitleContainer>
      <CustomIcon asset="speedometer-grey" width={ 61 } height={ 36 } />
      <Title>KEEP YOUR  MILEAGE UPDATED</Title>
    </TitleContainer>

    <Description>
      For the most accurate recommendations<br />
      please update your vehicle&#39;s current<br /> mileage provided by the odometer.
    </Description>

    <MileageWrapper showMileageCompareNotification={ showMileageCompareNotification }>
      <CustomMileageIcon asset="speedometer-grey" />
      <MileageValueWrapper>
        <Field
          { ...model.mileage }
          full
          static
          normalize={ integersOnly }
          format={ addCommas }
          component={ Input }
          onChange={ onChange } />
        <CustomMileageEntity>
          { MILEAGE_METRIC }
        </CustomMileageEntity>
      </MileageValueWrapper>

      {showMileageCompareNotification && (
        <WarningNotification>{MILEAGE_WARNING_NOTIFICATION}</WarningNotification>
      )}
    </MileageWrapper>


    <ButtonsWrapper>
      <ActionButton onClick={ handleSubmit }>
        UPDATE
      </ActionButton>
      <CustomButton
        showMileageCompareNotification={ showMileageCompareNotification }
        onClick={ handleHide }>
        Keep the current for now
      </CustomButton>
    </ButtonsWrapper>
  </CustomModal>

EditMileageModal.propTypes = {
  handleEditMileage: PropTypes.func,
  handleHide: PropTypes.func,
  handleSubmit: PropTypes.func,
  model: PropTypes.shape({
    mileage: PropTypes.shape()
  }),
  onChange: PropTypes.func,
  showMileageCompareNotification: PropTypes.bool
}

export default EditMileageModal

const CustomModal = styled(Modal)`
  width: 375px;
  height: 525px;

  & .modal-body {
    padding: 0 26px 26px;
    text-align: center;
  }

  @media (max-width: 500px) {
    width: 315px;
    height: auto;
  }
`

const TitleContainer = styled.div`
  padding: 0 0 15px 0;
  border-bottom: 2px solid ${Theme.colors.transparent_overlay};
  margin-bottom: 30px;
`

const CustomIcon = styled(Icon)`
  margin-bottom: 10px;
`

const Title = styled.div`
  ${Mixin.font.Arial(18, 700, 24)};
  margin: 0;
  color: ${Theme.colors.red};
`

const Description = styled.p`
  text-align: center;
  ${Mixin.font.Arial(13, 400, 16)};
  margin: 0 0 30px 0;
  color: ${Theme.colors.black};
`

const ButtonsWrapper = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CustomButton = styled(ActionButton)`
  margin: 30px 0 !important;
  color: ${Theme.colors.red};
  background: ${Theme.colors.transparent};
  text-transform: none;
  border: none;
  ${props => props.showMileageCompareNotification && css`
    margin: 25px 0 !important;
  `}

  &:hover {
    background: ${Theme.colors.transparent};
    color: ${Theme.colors.red};
  }
`

const CustomMileageEntity = styled(MileageEntity)`
  position: absolute;
  right: 20px;
  top: 15px;
  margin: 0 0 5px 5px;
  color: ${Theme.colors.sub_grey};
  opacity: 0.6;
`

const CustomMileageIcon = styled(MileageIcon)`
  position: absolute;
  left: 5px;
  top: 0;
  width: 47px;
  height: 28px;
`

const MileageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 37px;
  ${props => props.showMileageCompareNotification && css`
    margin-bottom: 70px;
  `}
`

const WarningNotification = styled.p`
  position: absolute;
  bottom: -52px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  width: 224px;
  text-align: start;
  color: ${Theme.colors.red};
`

const MileageValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 0 0 10px;
  width: 217px;

  @media (max-width: 500px) {
    width: 155px;
  }
`
