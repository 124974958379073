import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import { generateId, setRequiredAttrs } from '../../helpers'
import { dynamicDataAttribute } from 'helpers/utils'

// TODO generalize with RadioButton in common
const RadioButton = ({
  className,
  children,
  type = 'radio',
  topPosition,
  required,
  ...props
}) =>
  <RadioLabel
    className={ className }
    htmlFor={ generateId({ ...props }) }
    { ...dynamicDataAttribute('radio-label', props.value) }
    { ...props }>
    <RadioInput
      topPosition={ topPosition }
      type={ type }
      id={ generateId({ ...props }) }
      name={ props.name }
      value={ props.value }
      { ...setRequiredAttrs(required) }
      { ...props }
      tabIndex="0"
      data-at-input />
    <Text className={ 'radio-input-text' } data-at-text { ...props }>
      { props.label }
      { children }
    </Text>
  </RadioLabel>

RadioButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  required: PropTypes.bool,
  topPosition: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default RadioButton

const RadioLabel = styled.label`
  position: relative;
  display: inline-block;
  padding: 0 0 0 27px;
  margin: 0 0 10px;
  vertical-align: middle;
  cursor: pointer;

  & + & {
    ${Mixin.responsive('margin-left', '32px', '16px')}
  }
`

const Text = styled.div`
  display: inline-block;
  color: ${Theme.colors.black};
  letter-spacing: -0.4px;
  ${Mixin.font.Arial(14, 400)}
  ${props => props.disabled && css`
    color: ${Theme.colors.grey};
  `}
  ${Media.mobile`
    letter-spacing: -0.2px;
  `}
`

const RadioInput = styled.input`
  position: absolute;
  left: -9999px;

  & + ${Text} {
    &::before {
      content: '';
      position: absolute;
      top: ${props => props.topPosition ? '10px' : '50%'};
      left: 0;
      width: 16px;
      height: 16px;
      background: ${Theme.colors.white};
      border: 1px solid ${Theme.colors.grey};
      border-radius: 50%;
      transform: translateY(-50%);
      ${props => props.disabled && css`
        border-color: ${Theme.colors.light_grey};
      `}
    }
  }

  &:focus + ${Text} {
    &::before {
      ${Mixin.focusOutline()}
    }
  }

  &:checked + ${Text} {
    color: ${Theme.colors.black};

    &::after {
      content: '';
      position: absolute;
      top: ${props => props.topPosition ? '10px' : '50%'};
      left: 8px;
      width: 10px;
      height: 10px;
      ${Media.highContrast`
        width: 8px;
        height: 8px;
        border: 1px solid transparent;
      `}
      margin-top: -5px;
      margin-left: -4px;
      background: ${Theme.colors.red};
      border-radius: 50%;
    }
  }
`
