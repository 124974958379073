import React from 'react'
import styled, { css } from 'styled-components'
import Link from 'components/common/Link/Link'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import { filterProps } from 'helpers/utils'
import BOTApplyCreditCard from 'components/Header/BOTApplyCreditCard'

export const menuItemStyles = css`
  display: block;
  width: 100%;
  max-width: 350px;
  padding: 12px;
  ${Mixin.font.Arial(12, 400)}
  color: ${Theme.colors.black};

  :hover {
    position: relative;
    z-index: 2;
    margin-left: -1px;
    color: ${Theme.colors.white};
    text-decoration: none;
    background-color: ${Theme.colors.dark_grey};
  }

  ${Media.mobile`
    max-width: inherit;
    padding: 16px 0 16px 20px;
    font-size: 14px;
    border-top: 1px solid ${Theme.colors.light_grey};

    :hover {
      margin-left: 0;
      color: ${Theme.colors.black};
      background-color: transparent;
    }
  `}
`

const propsToRemove = [
  'altTag',
  'logout',
  '@type'
]

export const Item = styled(props => <Link { ...filterProps(props, propsToRemove) } />)`
  ${menuItemStyles}
`

export const ApplyNowItem = styled(BOTApplyCreditCard)`
  :not(.blispay-generic) .epjs-body-action-link {
    text-decoration: none;
  }

  .epjs-body-action-link {
    ${menuItemStyles}
  }
`
