import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'

const Step = ({ step, stepIndex, isSelected, isActive, isLastStep, onClick }) =>
  <Container data-at-appointment-step={ stepIndex } disabled={ !isActive } onClick={ onClick }>
    <IconWrapper selected={ isSelected }>
      <Icon asset="arrow-gray-down" />
    </IconWrapper>

    <Circle done={ step.isDone || isSelected }>
      {step.isDone ?
        <CheckIcon asset="check-white" /> :
        isLastStep ?
          <CheckIcon asset="check-gray" /> :
          <Number done={ step.isDone || isSelected }>{ stepIndex + 1 }</Number>}
    </Circle>

    <Title done={ step.isDone } selected={ isSelected }>{ step.title }</Title>
  </Container>

export default Step

Step.propTypes = {
  isActive: PropTypes.bool,
  isLastStep: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  step: PropTypes.shape({
    isDone: PropTypes.bool,
    title: PropTypes.string
  }),
  stepIndex: PropTypes.number
}

const Container = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
  text-align: center;
  width: 180px;
`

const IconWrapper = styled.div`
  text-align: center;
  visibility: ${props => props.selected ? 'visible' : 'hidden'};
  ${Media.mobile`
    display: none;
  `}
`

const CheckIcon = styled(Icon)`
  width: 15px;
  height: 15px;
  ${Media.mobile`
    width: 10px;
    height: 12px;
  `}
`

const Circle = styled.div`
  display: flex;
  background-color: white;
  border: 3px solid #bbb;
  border-radius: 15px;
  margin: 10px 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  ${props => props.done && css`
    background-color: #0f874b;
    border: none;
  `}
  ${Media.mobile`
    margin: 5px 0;
    width: 15px;
    height: 15px;
    ${props => !props.done && css`
      background-color: #bbb;
    `}
  `}
`

const Number = styled.div`
  ${Mixin.font.Arial(17, 700)}
  color: ${props => props.done ? 'white' : '#bbb'};
  ${Media.mobile`
    font-size: 12px;
    ${props => !props.done && css`
      color: white;
    `}
  `}
`

const Title = styled.div`
  color: ${props => (props.done || props.selected) ? 'black' : '#bbb'};
  text-decoration: ${props => props.selected ? 'underline' : 'none'};
  text-transform: uppercase;
  ${Mixin.font.ArialNarrow(16, 600)}
  ${Media.mobile`
    text-decoration: none;
    font-size: 12px;
  `}
`
