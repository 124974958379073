import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import { TileIcon, TileDescription } from '../SharedStyles'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'

const TrailerTile = ({ tireSize }) =>
  <Fragment>
    <TrailerIcon>
      <Icon asset="icon-trailer" width={ 80 } height={ 49 } alt="Trailer" data-at-trailer-icon />
    </TrailerIcon>
    <TrailerDescription data-at-trailer-description>
      Trailer
      <br />
      <TireSize>{ tireSize }</TireSize>
    </TrailerDescription>
  </Fragment>

TrailerTile.propTypes = {
  tireSize: PropTypes.string
}

export default TrailerTile

const TrailerIcon = styled(TileIcon.withComponent('div'))`
  ${Media.mobileNarrow`
    img {
      width: 70px;
      height: 35px;
    }
  `}
`

const TrailerDescription = styled(TileDescription)`
  padding-top: 12px;
  text-transform: capitalize;
`

const TireSize = styled.span`
  ${Mixin.font.Arial(12, 400)}
  ${Media.mobile`
    font-size: 11px;
  `}
`
