import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import SessionTimeOutMessage from 'components/common/SessionTimeOutMessage'
import { HOME, HEADER_TOP_NAVIGATOR, DESKTOP_NAVIGATOR_HEIGHT } from 'constant'

const HeaderDesktopMainContent = ({
  placeholders: { headerDesktopMainContentBottom = [], headerDesktopMainContentTop = []} = {},
  isTireComparisonHeaderDisplayed,
  isQuotePage,
  isQuickAppointmentPage
}) =>
  <Wrapper
    bottomBorder={ !isTireComparisonHeaderDisplayed }
    isQuotePage={ isQuotePage }
    isQuickAppointmentPage={ isQuickAppointmentPage }>
    <SessionTimeOutMessage />
    <NavTop
      id={ HEADER_TOP_NAVIGATOR }
      data-max-width
      data-sticky-hide
      data-top-nav >
      { headerDesktopMainContentTop }
    </NavTop>
    <Separator data-sticky-hide />
    {(!isQuotePage && !isQuickAppointmentPage) && (
      <Header data-max-width>
        <LogoWrapper data-at-header-logo>
          <Link to={ HOME }>
            <Icon asset="logo" alt="BigO Tire" width={ 228 } height={ 27 } />
          </Link>
        </LogoWrapper>
        <NavBottom id="menu-primary" aria-label="Primary Menu" role="navigation">
          { headerDesktopMainContentBottom }
        </NavBottom>
      </Header>
    )}
  </Wrapper>

HeaderDesktopMainContent.propTypes = {
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  isQuickAppointmentPage: PropTypes.bool,
  isQuotePage: PropTypes.bool,
  isTireComparisonHeaderDisplayed: PropTypes.bool,
  placeholders: PropTypes.shape({
    headerDesktopMainContentBottom: PropTypes.arrayOf(PropTypes.shape()),
    headerDesktopMainContentTop: PropTypes.arrayOf(PropTypes.shape())
  })
}

export default HeaderDesktopMainContent

const Wrapper = styled.header`
  display: block;
  background: ${Theme.colors.white};

  [data-sticky] & {
    ${props => (!props.isQuotePage && !props.isQuickAppointmentPage) && css`
      height: 60px;
    `}
    ${props => props.bottomBorder && (!props.isQuotePage && !props.isQuickAppointmentPage) && css`
      box-shadow: 0 2px 4px ${Theme.colors.transparent_grey_hover};
    `}
  }
`

const NavTop = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${DESKTOP_NAVIGATOR_HEIGHT}px;
  padding: 0 40px;
  background: ${Theme.colors.white};
  text-align: right;
`

const Separator = styled.div`
  border-bottom: 1px solid ${Theme.colors.light_grey};
`

const Header = styled.div`
  display: table;
  height: 70px;
  padding: 0 40px;
  background-color: ${Theme.colors.white};

  [data-sticky] & {
    height: 60px;
  }
`

const LogoWrapper = styled.div`
  display: table-cell;
  width: 228px;
  text-align: center;
  vertical-align: middle;

  [data-sticky] & {
    img {
      width: 251px;
      height: 25px;
    }
  }
`

const NavBottom = styled.nav`
  display: table-cell;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  vertical-align: middle;

  > div,
  [data-at-header-menu] {
    display: inline-block;
  }
`
