import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'

const ButtonStaggered = ({ tireRearSize, tireFrontSize, ...props }) =>
  <CustomButton { ...props } full follow stacked data-at-button-staggered>
    <FrontTires>
      <TireTitle>
        Front Tires
      </TireTitle>
      <TireSize>
        { tireFrontSize }
      </TireSize>
    </FrontTires>
    <BackTires>
      <TireTitle>
        Rear Tires
      </TireTitle>
      <TireSize>
        { tireRearSize }
      </TireSize>
    </BackTires>
  </CustomButton>

ButtonStaggered.propTypes = {
  onClickHandler: PropTypes.func,
  tireFrontSize: PropTypes.string,
  tireRearSize: PropTypes.string
}

export default ButtonStaggered

const CustomButton = styled(Button)`
  transform: none;
  min-width: auto;
  text-align: left;
  ${Mixin.responsive('padding', '8px 44px 8px 20px', '12px 44px 0 18px')};
`

export const FrontTires = styled.div`
  ${Media.desktop`
    display: inline-block;
    max-width: 50%;
    padding-right: 15px;
  `}
  ${Mixin.responsive('padding-bottom', '0', '8px')}
  ${Mixin.clearFix()}
`

export const BackTires = styled(FrontTires)`
  ${Media.desktop`
    border-left: 1px solid ${Theme.colors.white};
    padding-left: 15px;
  `}
  ${Mixin.responsive('padding-top', '0', '10px')}
  ${Mixin.responsive('border-top', 'none', `1px solid ${Theme.colors.white}`)}
`

export const TireTitle = styled.div`
  text-transform: capitalize;
  ${Mixin.font.Arial(14, 600, 18)}
  ${Media.mobile`
     display: inline-block;
     float: right;
     width: 50%;
     text-align: right;
  `}
`

export const TireSize = styled(TireTitle)`
  ${Mixin.font.Factoria(18, 24)}
  ${Media.mobile`
     text-align: left;
  `}
`
