import { handleActions } from 'redux-actions'
import {
  setDateData,
  setServicesList,
  setVehiclesList,
  setQuoteProductAndServices,
  setIsTrailer,
  clearQuickAppointmentData,
  setAppointmentActiveVehicle,
  setAppointmentResponse,
  setQuickApptInitialized,
  setPreSelectedServices,
  setCalendarStartDateList,
  setClearCalendarStartDateList
} from './actions'

const initialState = {
  dates: null,
  vehicles: [],
  servicesList: null,
  isTrailer: false,
  appointmentResponse: null,
  initialized: false,
  preSelectedServices: [],
  calendarStartDateList: []
}

const handleDateSet = (state, { payload: appointmentDateAndSlotsVO }) => ({
  ...state,
  dates: appointmentDateAndSlotsVO ? appointmentDateAndSlotsVO.reduce((acc, {
    appointmentDate,
    allAvailableTimes = []
  }) => {
    const [month, day, year] = appointmentDate.split('/')
    const monthKey = `${month}/${year}`
    const monthData = acc[monthKey]

    return {
      ...acc,
      [monthKey]: {
        ...monthData,
        [day]: allAvailableTimes.map(({ time, meridiem = '' }) => ({
          displayName: `${time} ${meridiem.toLowerCase()}`,
          value: `${time} ${meridiem.toLowerCase()}`
        }))
      }
    }
  }, {}) : null
})

const getServices = (state, { payload }) => ({
  ...state,
  servicesList: payload.reduce((acc, { services }) => ({
    ...acc,
    ...services.reduce((servicesAcc, { skuId, displayName }) => ({
      ...servicesAcc,
      [skuId]: displayName
    }), {})
  }), {})
})

const getPreSelectedServices = (state, { payload }) => ({
  ...state,
  preSelectedServices: payload
})

const handleCalendarStartDateList = (state, { payload }) => ({
  ...state,
  calendarStartDateList: [
    ...state.calendarStartDateList,
    ...payload
  ]
})

const clearCalendarStartDateList = (state, { payload }) => ({
  ...state,
  calendarStartDateList: []
})

const getIsTrailer = (state, { payload }) => ({
  ...state,
  isTrailer: payload
})

const getVehicles = (state, { payload }) => ({ ...state, vehicles: [...payload]})

const getActiveVehicle = (state, { payload: { userVehicleId: id }}) => ({
  ...state,
  vehicles:
    state.vehicles.map(({ userVehicleId, ...vehicle }) =>
      ({ ...vehicle, userVehicleId, isActiveVehicle: Boolean(userVehicleId === id) }))
})

const getQuoteProductAndServices = (state, { payload: { services = [], products = []} = {}} = {}) => ({
  ...state,
  quoteProductsAndCervices: [
    ...products.map(({ productName, qty }) => `(${qty}) ${productName}`),
    ...services.map(({ name }) => name)
  ].join(', ')
})

const setAppointmentResponseData = (state, { payload }) => ({
  ...state,
  appointmentResponse: payload
})

const setInitialized = (state, { payload }) => ({
  ...state,
  initialized: payload
})

const appointment = handleActions({
  [setIsTrailer]: getIsTrailer,
  [setDateData]: handleDateSet,
  [setServicesList]: getServices,
  [setVehiclesList]: getVehicles,
  [setClearCalendarStartDateList]: clearCalendarStartDateList,
  [setCalendarStartDateList]: handleCalendarStartDateList,
  [setAppointmentActiveVehicle]: getActiveVehicle,
  [setQuoteProductAndServices]: getQuoteProductAndServices,
  [setAppointmentResponse]: setAppointmentResponseData,
  [clearQuickAppointmentData]: () => ({
    ...initialState
  }),
  [setQuickApptInitialized]: setInitialized,
  [setPreSelectedServices]: getPreSelectedServices
}, initialState)

export default appointment
