import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'

const CustomPromotionModal = ({
  handleCloseModal,
  components: {
    BeforeComponent = <></>,
    TimerComponent = <></>,
    AfterComponent = <></>
  } = {}
}) =>
  <CustomModal closeHandler={ handleCloseModal } className="promotion-countdown-modal">
    <ModalContentWrapper className="promotion-countdown-modal__content">
      {BeforeComponent}
      {TimerComponent}
      {AfterComponent}
    </ModalContentWrapper>
  </CustomModal>

CustomPromotionModal.propTypes = {
  components: PropTypes.shape({
    BeforeComponent: PropTypes.shape({}),
    TimerComponent: PropTypes.shape({}),
    AfterComponent: PropTypes.shape({})
  }),
  handleCloseModal: PropTypes.func
}

export default CustomPromotionModal

const CustomModal = styled(Modal)`
  ${Mixin.responsive('padding', '25px 24px 46px', '60px 16px 40px')}
`

const ModalContentWrapper = styled.div`
  display: block;
`
