import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'

export const Underline = styled.div`
  border-bottom: 1px solid ${Theme.colors.light_grey};
  margin: ${props => props.margin || '0'};
  ${props => props.ymme && css`
    && {
      ${Mixin.responsive('margin', '0 0 25px 0', '0 0 21px 0')}
    }
  `}
`
export const TabSelectorWrapper = styled.div`
  margin-bottom: -1px;
  ${Media.mobile`
    display: table;
    padding: 0 10px;
  `}
  ${props => props.ymme && css`
    && {
      display: table;
      width: 100%;
      padding: 0;
      margin-left: 0;
      margin-bottom: -2px;
    }
  `}
  ${props => props.modal && css`
    ${Media.desktop`
      margin-left: 24px;
    `}
    ${Media.mobile`
      padding: 0 16px;
    `}
  `}
`

export const TabItem = styled.button.attrs(({ isActive }) => ({
  'data-active': isActive
}))`
  display: table-cell;
  color: ${Theme.colors.dark_grey};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  ${Mixin.responsive('padding', '12px 30px 10px', '12px 12px 10px')}
  ${Mixin.font.ArialNarrow(14, 600)}
  background: ${Theme.colors.off_white};
  border: 1px solid ${Theme.colors.light_grey};

  :hover {
    text-decoration: underline;
  }

  ${Media.mobile`
    height: 40px;
  `}

  ${props => !props.ymme && Media.mobile`
    position: relative;
    padding: 2px 10px 0;
    vertical-align: middle;
    ${Mixin.font.ArialNarrow(11, 600)}

    .active &::before, &[data-active="true"]::before {
      ::before {
        position: absolute;
        top: -1px;
        right: -1px;
        left: -1px;
        height: 3px;
        background: white;
        border: none;
        border-bottom: 1px solid ${Theme.colors.light_grey};
        content: '';
      }
    }
  `}
  ${props => !props.ymme && css`
    border-right: none;

    :last-child {
      border-right: 1px solid ${Theme.colors.light_grey};
    }
    position: relative;
    z-index: 1;

    .active,
    &[data-active="true"] {
      position: relative;
      z-index: 0;

      ::before,
      ::after {
        position: absolute;
        right: -1px;
        left: -1px;
        content: '';
      }

      ::before {
        top: -5px;
        border-top: 2px solid ${Theme.colors.red};
      }

      ::after {
        top: -3px;
        border-left: 1px solid ${Theme.colors.light_grey};
        border-right: 1px solid ${Theme.colors.light_grey};
        background: ${Theme.colors.white};
        height: 4px;
      }
    }
  `}

  ${props => props.ymme && css`
    && {
      position: relative;
      display: table-cell;
      width: 50%;
      padding: 14px 5px 11px;
      vertical-align: middle;
      ${Media.mobile`
        height: 49px;
      `}
      ${Mixin.font.ArialNarrow(14, 600)}
      ${props => props.isActive && css`
        ::before {
          position: absolute;
          top: -4px;
          right: 0;
          left: 0;
          border-bottom: 5px solid ${Theme.colors.white};
          border-top: 2px solid ${Theme.colors.red};
          content: '';
        }
      `}

      .active &,
      &[data-active="true"] {
        padding: 14px 5px 11px;
        border-left: none;
        border-right: none;
      }
    }
  `}

  .active &,
  &[data-active="true"] {
    padding: 12px 30px 10px;
    color: ${Theme.colors.red};
    cursor: default;
    background-color: ${Theme.colors.white};
    border-bottom: none;

    :hover {
      text-decoration: none;
    }

    ${Media.mobile`
      padding: 1px 12px;
      border-top: none;
    `}
  }
`

export const Circle = styled.span`
  display: inline-block;
  height: 1.4em;
  min-width: 1.9em;
  padding: 0 4px;
  margin: 0 8px;
  line-height: 15px;
  color: ${Theme.colors.white};
  text-align: center;
  vertical-align: middle;
  background-color: ${Theme.colors.red};
  border-radius: 45%;
  ${Mixin.font.Arial(11, 600)}
  ${Mixin.desktopOnly()}
`

export const TabPanel = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
`
