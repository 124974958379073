import React from 'react'
import PropTypes from 'prop-types'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import styled from 'styled-components'

const FooterBottomContentBlock = ({ placeholders: { footerContents = []} = {}, ...props }) =>
  <CopyrightWrapper data-background-src="//tbc.scene7.com/is/image/TBCCorporation/bot-footer-bg?wid=1084&qlt=50&op_blur=1">
    <div data-max-width>
      { footerContents }
    </div>
  </CopyrightWrapper>

FooterBottomContentBlock.propTypes = {
  '@type': PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    footerContents: PropTypes.arrayOf(PropTypes.shape())
  })
}

FooterBottomContentBlock.type = 'FooterBottomContentBlock'
FooterBottomContentBlock.placeholders = ['footerContents']

export default FooterBottomContentBlock

const CopyrightWrapper = styled.div`
  background-image: url('//tbc.scene7.com/is/image/TBCCorporation/bot-footer-bg?wid=1084&qlt=50&op_blur=1');
  background-color: ${Theme.colors.black};
  background-size: cover;
  ${Media.mobile`
    padding: 25px 0;
    text-align: center;
  `}
  ${Media.desktop`
    padding: 16px 50px;
    border-top: 1px solid ${Theme.colors.dark_grey};
  `}
`
