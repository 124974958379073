import React from 'react'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import { isApiError } from 'services'
import {
  BREAD_LOCATION_FOOTER,
  BREAD_PLACEMENT_ID_FOOTER
} from 'constant'
import { getItemsWithExpiry } from 'services/ThirdPartyMiddleware/helpers.js'

export const injectProps = (component, props) =>
  React.Children.map(component, content => React.cloneElement(content, props))
export const noop = () => {}
export const nullComponent = () => null
export const isFunction = arg => typeof arg === 'function'
export const preventDefault = func => (e, ...args) => {
  e.preventDefault()
  func(e, ...args)
}
export const isDefined = variable => typeof variable !== 'undefined'
export const arrayHasValues = ary => !!ary && !!ary.length
export const getArrayObjectByParam = ({ array = [], attr = 'name', value }) =>
  Array.isArray(array) ? array.filter(item => isPlainObject(item) && item[attr] === value) : []

export const uid = () =>
  Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)

export const getFullYear = () => (new Date()).getFullYear()

export const getHeightById = ({ id, fallback = 0 }) => {
  const target = document.getElementById(id)
  return target ? target.offsetHeight : fallback
}

export const getLeftPostionInPixelsById = ({ id, fallback = 0 }) => {
  const target = document.getElementById(id)
  return target ? target.offsetLeft : fallback
}

export const isElementHidden = ({ id }) => {
  const target = document.getElementById(id)
  return target ? target.offsetParent === null : true
}

export const decodeHTML = html => {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = html
  return textArea.value
}

export const dynamicDataAttribute = (attribute, value = true) => ({ [`data-at-${attribute}`]: value })
export const getDataAtProps = (props) => pickBy(props, (_, key) => key.startsWith('data-at-'))
export const toOneString = name => String(name || '').toLowerCase().replace(/[^\w]+/g, '')

export const isErrorCode = ({ err: { status = {}, statusList = [{}]}, err = {}, code: statusCode }) =>
  isApiError(err) && (statusList.some(({ code }) => code === statusCode) || (status && status.code === statusCode))

export const isErrorDescription = ({
  err: { status = {}, statusList = [{}]},
  err = {}, description: statusDescription
}) =>
  isApiError(err) && (statusList.some(({ description }) => description === statusDescription) ||
    (status && status.description === statusDescription))

export const filterProps = (props, propsToRemove) => omit({ ...props }, propsToRemove)

export const setLinkDataAttrVal = (link) => {
  const { props: { children: { props: { displayName = '' } = {}} = {}} = {}} = link
  return toOneString(displayName)
}

export const setFooterApplyNowProp = ({
  props: {
    type
  } = {}
}) => type === 'BOTApplyCreditCard' ? { locationType: BREAD_LOCATION_FOOTER, placementId: BREAD_PLACEMENT_ID_FOOTER }
  : {}

export const formatPrice = (price) => {
  const value = Number(price) || 0
  const negative = value < 0
  const abs = Math.abs(value).toFixed(2)
  return negative ? `-$${abs}` : `$${abs}`
}

export const isComponentType = (componentType = '', {
  props: {
    type
  } = {}
}) => componentType === type

export const hideHorizontalScroll = element => {
  const htmlElement = element
  htmlElement.style.overflowX = 'hidden'
}

export const isInventoryStatusLoaded = (inventoryStatuses, productSKUId) => (
  !isEmpty(inventoryStatuses) &&
    inventoryStatuses.find(item => item.skuId === productSKUId)?.isLoading
)

export const isPilotStore = () => {
  const pilotStoreInfo = getItemsWithExpiry('pilotStoreInfo')
  return pilotStoreInfo?.isPilotStore
}
