import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'

const VehicleSelectorContentBlock = ({
  description,
  title
}) =>
  <Wrapper data-at-vehicle-selector-content-block>
    <CarIcon asset="no-vehicle" width={ 104 } height={ 36 } data-at-car-icon alt="" />
    <Title data-at-title>{ title }</Title>
    <TireDescription data-at-tire-description>{ description }</TireDescription>
  </Wrapper>

VehicleSelectorContentBlock.type = 'VehicleSelectorContentBlock'

VehicleSelectorContentBlock.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string
}

export default VehicleSelectorContentBlock

const Wrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`

const CarIcon = styled(Icon)`
  float: left;
  ${Mixin.responsive('margin-right', '40px', '15px')}
  ${Media.mobile`
    width: 58px;
    height: 19px;
  `}
`

const Title = styled.div`
  color: ${Theme.colors.black};
  ${Mixin.responsive('margin', '0 0 10px', ' 5px 0 10px')}
  ${Mixin.font.Arial(18, 600)}
  ${Media.mobile`
    ${Mixin.ellipsis()}
    ${Mixin.font.Arial(14, 600)}
  `}
`

const TireDescription = styled.div`
  ${Mixin.desktopOnly()}
`
