import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import { TAB_YMME_NAME, TAB_LICENSE_PLATE_NAME, TAB_TIRE_SIZE_NAME } from 'constant'

const Tabs = ({ tab, setTabHandler, isOnlyVehicle, isOnlyTireSize }) =>
  <Wrapper>
    {
      !isOnlyTireSize && (
        <>
          <Tab
            aria-label={ tab === TAB_YMME_NAME ? 'Active vehicle tab' : 'Inactive Vehicle tab' }
            active={ tab === TAB_YMME_NAME }
            onClick={ () => setTabHandler(TAB_YMME_NAME) }>
            <CustomIcon asset="shop-by-vehicle" />
            Vehicle
          </Tab>
          <Tab
            aria-label={ tab === TAB_LICENSE_PLATE_NAME ? 'Active License Plate tab' : 'Inactive License Plate tab' }
            active={ tab === TAB_LICENSE_PLATE_NAME }
            onClick={ () => setTabHandler(TAB_LICENSE_PLATE_NAME) }>
            <CustomIcon asset="shop-by-license-plate" />
            License Plate
          </Tab>
        </>
      )
    }
    { !isOnlyVehicle &&
      <Tab
        aria-label={ tab === TAB_TIRE_SIZE_NAME ? 'Active Tire Size tab' : 'Inactive Tire Size tab' }
        active={ tab === TAB_TIRE_SIZE_NAME }
        onClick={ () => setTabHandler(TAB_TIRE_SIZE_NAME) }>
        <CustomIcon asset="shop-by-tire-size" />
        Tire Size
      </Tab>
    }
  </Wrapper>

export default Tabs

Tabs.propTypes = {
  isOnlyTireSize: PropTypes.bool,
  isOnlyVehicle: PropTypes.bool,
  setTabHandler: PropTypes.func,
  tab: PropTypes.string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Tab = styled.button`
  text-transform: uppercase;
  ${Mixin.font.Arial(15, 400)};
  color: ${Theme.colors.black};
  padding: 15px 30px;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${props => props.active && css`
    background-color: ${Theme.colors.white};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
  `}

  @media (max-width: 800px) {
    ${Mixin.font.Arial(14, 400)};
  }

  @media (max-width: 700px) {
    flex-direction: column;
    text-transform: capitalize;
    color: ${Theme.colors.medium_grey_ui};
    ${Mixin.font.Arial(12, 400)};
    padding: 15px 25px 5px;
  }

  @media (max-width: 460px) {
    padding: 15px 10px 5px;
  }

  @media (max-width: 370px) {
    padding: 15px 7px 5px;
  }
`

const CustomIcon = styled(Icon)`
  height: 16px;
  margin: 0 15px 0 0;

  @media (max-width: 700px) {
    margin: 0 0 6px 0;
  }
`

