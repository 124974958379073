import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Step from './Step'

const StepperHeader = ({
  steps,
  currentStepIndex,
  progressBarWidth,
  title: headerTitle,
  nextStepHandler,
  noLastStep
}) =>
  <Container>
    <Title>{ headerTitle }</Title>
    <Steps>
      {steps.map((step, index) =>
        <Step
          key={ step.title }
          step={ step }
          stepIndex={ index }
          isDone={ step.isDone }
          isActive={ step.isActive }
          isSelected={ index === currentStepIndex }
          isLastStep={ !noLastStep && index === steps.length - 1 }
          onClick={ () => nextStepHandler(index) } />) }
      <ProgressBar extend={ steps.length > 3 }>
        <ProgressLine width={ progressBarWidth } />
      </ProgressBar>
    </Steps>
  </Container>

export default StepperHeader

StepperHeader.propTypes = {
  currentStepIndex: PropTypes.number,
  nextStepHandler: PropTypes.func,
  noLastStep: PropTypes.bool,
  progressBarWidth: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
}

const Container = styled.div`
  padding: 20px 100px;
  box-shadow: 0 0 10px rgba(113, 113, 113, 0.349);
  ${Media.mobile`
    padding: 10px;
  `}
`

const Title = styled.div`
  ${Mixin.font.ArialNarrow(26, 600)}
  text-transform: uppercase;
  text-align: center;
  ${Media.mobile`
    display: none;
  `}
`

const Steps = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`

const ProgressBar = styled.div`
  position: absolute;
  z-index: -10;
  top: 40px;
  left: 90px;
  right: 90px;
  height: 4px;
  background-color: #bbb;
  ${Media.mobile`
    top: 12px;
    height: 2px;
    left: ${props => props.extend ? '15%' : '20%'};
    right: ${props => props.extend ? '15%' : '20%'};
  `}
`

const ProgressLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${props => props.width}%;
  background-color: #0f874b;
`
