import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import { ErrorMessage } from '../Input/CommonTextInput'
import checkedBg from './assets/Checked.svg'
import checkedBgDisabled from './assets/Checked_Disabled.svg'
import defaultBg from './assets/Default.svg'
import { generateId, setRequiredAttrs } from '../helpers'
import { dynamicDataAttribute } from 'helpers/utils'

const Checkbox = ({
  id,
  children,
  color,
  className,
  label = '',
  onChange,
  onKeyDown,
  isError,
  error,
  dataAttributes,
  disabled,
  required,
  ...props
}) => {
  const inputId = id || generateId({ ...props, label })

  return (
    <CheckboxWrapper { ...props } className={ className } { ...dataAttributes }>
      <CheckboxLabel
        data-checkbox
        htmlFor={ inputId }
        checked={ props.value }
        color={ color }>
        <CheckboxInput
          disabled={ disabled }
          { ...props }
          id={ inputId }
          type="checkbox"
          checked={ props.value }
          aria-invalid={ isError }
          tabIndex="0"
          { ...setRequiredAttrs(required) }
          { ...dynamicDataAttribute('checkbox-input', props.name) }
          onChange={ onChange }
          onKeyDown={ onKeyDown } />
        <Text data-checkbox-text { ...props }>
          { label }
          { children }
        </Text>
      </CheckboxLabel>
      {
        isError &&
        <CustomErrorMessage
          htmlFor={ inputId }
          data-at-checkbox-error-message
          { ...props }>
          { error }
        </CustomErrorMessage>
      }
    </CheckboxWrapper>
  )
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  dataAttributes: PropTypes.shape(),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape()
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.bool]
  )
}

export default Checkbox

const CheckboxWrapper = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 0 25px 0'};
`

const CheckboxLabel = styled.label`
  position: relative;
  display: inline-block;
  color: ${props => props.color || Theme.colors.dark_red};
  vertical-align: middle;
  cursor: pointer;
`

const Text = styled.span`
  display: inline-block;
  padding-left: 1.857em;
  vertical-align: middle;
  line-height: 20px;
  color: ${Theme.colors.black};
  ${props => props.size && css`
    font-size: ${props.size}px;
  `}
`

const CheckboxInput = styled.input`
  position: absolute;
  z-index: 10;
  top: 0.143em;
  width: 1.14em;
  height: 1.14em;
  background: none;
  appearance: none;

  ::-ms-check {
    display: none;
  }

  & + ${Text} {
    &::before {
      content: '';
      position: relative;
      z-index: 20;
      display: inline-block;
      float: left;
      width: 0.857em;
      height: 0.857em;
      margin: 0.143em 0 0 -1.857em;
      padding: 0.143em;
      background: ${Theme.colors.transparent} url(${defaultBg}) center/contain no-repeat;
      ${Media.print`
        border: 1px solid ${Theme.colors.medium_grey};
      `}
    }
  }

  &:checked + ${Text} {
    &::before {
      background: ${Theme.colors.transparent} url(${checkedBg}) center/contain no-repeat;
      ${Media.highContrast`
        border: solid 1px transparent;
      `}
      ${Media.print`
        height: 0;
        width: 1.143em;
        padding: 0;
        border-left: none;
        border-right: none;
        border-top: .571em solid ${Theme.colors.red};
        border-bottom: .571em solid ${Theme.colors.red};
      `}
    }
  }

  &[disabled]:checked + ${Text} {
    &::before {
      background: ${Theme.colors.transparent} url(${checkedBgDisabled}) center/contain no-repeat;
      ${Media.highContrast`
        border: solid 1px transparent;
      `}
      ${Media.print`
        border-top: .571em solid ${Theme.colors.medium_grey};
        border-bottom: .571em solid ${Theme.colors.medium_grey};
      `}
    }
  }

  &:checked + ${Text},
  &[disabled]:checked + ${Text} {
    &::after {
      ${Media.print`
        content: '';
        position: absolute;
        z-index: 30;
        height: .357em;
        width: .714em;
        border-left: .143em solid ${Theme.colors.white};
        border-bottom: .143em solid ${Theme.colors.white};
        transform: rotate(-45deg);
        left: .143em;
        top: .357em;
      `}
    }
  }

  &:required {
    box-shadow: none;
  }
`

const CustomErrorMessage = styled(ErrorMessage)`
  padding-left: 1.857em;
  position: relative;
  top: 0;
`
