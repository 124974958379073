import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import PromotionExpirationModal from './PromotionExpirationModal'
import { PROMOTION_EXPIRATION_MODAL } from '../../constants'
import { removePromoFromCart, setModalWasShown, setPromotionDetails } from './actions'
import { formatPhoneNumber } from 'helpers/phone-number'
import { getCurrentStorePhoneNumber } from 'selectors/getCurrentStore'
import { setShoppingData } from 'actions/shoppingData'
import { setShowInRangePromoExpirationNotification } from 'components/ShoppingQuote/BOTProductAndServicesQuoteNew/components/PromotionExpirationModal/actions.js'

const mapStateToProps = (state) => ({
  storePhoneNumber: getCurrentStorePhoneNumber(state),
  formattedStorePhoneNumber: formatPhoneNumber(getCurrentStorePhoneNumber(state))
})

const withExpirationPromoModalHandlers = withHandlers({
  keepPromoHandler: ({
    handleHide,
    onChange,
    setSelectedDate,
    firstAvailableDate,
    quotedItemAndServices,
    setShoppingData,
    setModalWasShown,
    setShowInRangePromoExpirationNotification
  }) => () => {
    onChange({ date: firstAvailableDate, time: undefined })
    setSelectedDate({ date: firstAvailableDate, time: undefined })
    setShoppingData({ quotedItemAndServices })
    setModalWasShown(false)
    setShowInRangePromoExpirationNotification(false)
    handleHide()
  },
  removePromoHandler: ({
    handleHide,
    appliedPromotions,
    removePromoFromCart,
    setPromotionDetails
  }) => () => {
    const promoIds = appliedPromotions.map(({ promotionId }) => promotionId)
    setPromotionDetails(appliedPromotions)
    removePromoFromCart({ promoIds })
    handleHide()
  }
})

export default compose(
  registerReduxModal({ name: PROMOTION_EXPIRATION_MODAL, destroyOnHide: true }),
  connect(mapStateToProps, {
    removePromoFromCart,
    setShoppingData,
    setModalWasShown,
    setPromotionDetails,
    setShowInRangePromoExpirationNotification
  }),
  withExpirationPromoModalHandlers
)(PromotionExpirationModal)
