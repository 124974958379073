import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import { isFunction, filterProps, dynamicDataAttribute, toOneString } from 'helpers/utils'
import * as UI from './SharedStyles'
import { generateId, setRequiredAttrs } from '../helpers'

const CommonTextInput = ({
  Icon = null,
  children,
  error,
  active,
  label,
  type,
  className,
  disabled,
  mask,
  isError,
  textIndent,
  dropdown,
  full = false,
  required,
  static: staticFlag,
  ...props
}) => {
  const id = generateId({ ...props, label })
  return (
    <UI.Wrapper className={ className } full={ full }>
      {
        label &&
        <InputLabel
          isError={ isError }
          isFilled={ Boolean(props.value) || active || props.placeholder }
          disabled={ disabled }
          htmlFor={ id }
          textIndent={ textIndent }
          data-label>
          { label }
        </InputLabel>
      }

      <InputComponent
        { ...props }
        mask={ props.value && mask }
        maskChar={ null }
        type={ type }
        placeholder={ props.placeholder }
        id={ id }
        disabled={ disabled }
        isError={ isError }
        autoComplete="off"
        textIndent={ textIndent }
        dropdown={ dropdown }
        { ...setRequiredAttrs(required) }
        { ...dynamicDataAttribute('text-input', toOneString(label)) }
        data-input />

      <IconLabel data-non-empty htmlFor={ id }>
        { Icon }
      </IconLabel>

      { children }

      { isError &&
      <ErrorMessage
        htmlFor={ id }
        disabled={ disabled }
        textIndent={ textIndent }
        static={ staticFlag }>{ isFunction(error) ? error(props) : error }</ErrorMessage> }
    </UI.Wrapper>
  )
}

export default CommonTextInput

CommonTextInput.propTypes = {
  active: PropTypes.bool,
  bordered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropdown: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  full: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handlers: PropTypes.shape(),
  Icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node
  ]),
  input: PropTypes.shape(),
  inputRef: PropTypes.func,
  isError: PropTypes.bool,
  label: PropTypes.string,
  mask: PropTypes.string,
  msHideCross: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  static: PropTypes.bool,
  textIndent: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.node
}

const propsToRemove = [
  'errorStyle',
  'isError',
  'textIndent',
  'rightIndent',
  'msHideCross',
  'staticContext',
  'isPasswordFieldActive',
  'handleRedirect',
  'handleToggleBlur',
  'setToggleBlockRef',
  'toggle',
  'small',
  'formatValueDisplay',
  'hasError'
]

const InputMaskWithRef = ({ inputRef, ...props }) =>
  <InputMask
    ref={ inputRef ? (ref) => inputRef(ref ? ref.input : null) : undefined }
    { ...filterProps(props, propsToRemove) } />

InputMaskWithRef.propTypes = {
  inputRef: PropTypes.func
}

export const InputComponent = styled(InputMaskWithRef)`
  ${Mixin.font.Arial(14, 600)}
  width: 100%;
  height: 2.85em;
  padding-left: ${props => props.textIndent || 10}px;
  padding-right: ${props => props.rightIndent || 0}px;
  color: ${Theme.colors.black};
  background: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.grey};
  outline: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  ${props => props.dropdown && css`
    cursor: pointer;
  `}
  ${props => props.isError && css`
    border: 1px solid ${Theme.colors.bright_red};
  `}
  ${props => props.errorStyle && props.isError && css`
    color: ${Theme.colors.red};
  `}
  ${props => props.disabled && css`
    border: 1px solid ${Theme.colors.grey};
    background-color: ${Theme.colors.off_white};
  `}
  border-radius: 0;

  :focus {
    border: 1px solid ${Theme.colors.transparent_light_blue};
    box-shadow: 0 0 2px ${Theme.colors.transparent_blue};
    outline: 0;
    ${props => props.isError && css`
      border: 1px solid ${Theme.colors.bright_red};
    `}
    ${props => props.bordered && css`
      border-bottom: 1px solid ${Theme.colors.light_grey};
    `}
    ${props => props.msHideCross && css`
      ::-ms-clear {
        display: none;
      }
    `}
  }

  :required {
    box-shadow: none;
  }
`

const IconLabel = styled.span`
  display: block;
`

export const InputLabel = styled.label`
  position: absolute;
  top: 11px;
  left: ${props => props.textIndent ? props.textIndent + 1 : 11}px;
  color: ${Theme.colors.dark_grey};
  ${Mixin.font.Arial(14, 400)}
  ${props => props.isError && css`
    color: ${Theme.colors.black};
  `}
  ${props => props.disabled && css`
    color: ${Theme.colors.grey};
  `}
  ${props => props.isFilled && css`
    color: ${Theme.colors.dark_grey};
    top: -11px;
    left: 11px;
    padding: 2px;
    background-color: ${Theme.colors.white};
    ${Mixin.font.Arial(12)}
    ${props => props.isError && css`
      color: ${Theme.colors.bright_red};
    `}
  `}
  ${Media.mobile`
    ${props => !props.isFilled && css`
      ${Mixin.ellipsis()}
      right: 11px;
    `}
  `}
`

export const ErrorMessage = styled(InputLabel).attrs({
  'data-error-message': true,
  'data-at-input-error-message': true
})`
  /* stylelint-disable */
  /* Error message specific props */
  ${Mixin.font.Arial(12, 400)}
  color: ${Theme.colors.bright_red};
  left: 0;
  top: 46px;
  ${props => props.static && css`
    position: static;
    display: inline-block;
    margin-top: 7px;
  `}

  &[data-at-dropdown-error-message][aria-live] {
    display: block;
  }
`
