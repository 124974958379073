import { createGlobalStyle } from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import FactoriaUltraTTF from './fonts/factoria_ultra-webfont.ttf'
import FactoriaUltraWOFF from './fonts/factoria_ultra-webfont.woff'
import FactoriaUltraWOFF2 from './fonts/factoria_ultra-webfont.woff2'
import { MAX_WIDTH } from 'constant'
import ArrowIconFullRedRight from 'components/common/Icon/assets/arrow-icon-full-red-right.svg'
import FilledDownArrow from 'components/common/Icon/assets/Filled-down-arrow.svg'
import IconInfoBlueRegular from 'components/common/Icon/assets/Icon-info-blue-regular.svg'
import IconInfoBlueSolid from 'components/common/Icon/assets/Icon-info-blue-solid.svg'

const GlobalStyles = createGlobalStyle`
  [hidden] {
    display: none !important;
  }

  [data-non-empty]:empty {
    display: none;
  }

  [data-cta] {
    cursor: pointer;
  }

  [data-sticky] {
    [data-sticky-hide] {
      display: none !important;
    }
  }

  [data-sr-only] {
    ${Mixin.srOnly()}
  }

  [data-desktop-only] {
    ${Mixin.desktopOnly()}
  }

  [data-mobile-only] {
    ${Mixin.mobileOnly()}
  }

  [data-relative] {
    position: relative;
  }

  [data-max-width] {
    max-width: ${MAX_WIDTH}px;
    margin: 0 auto;
  }

  [data-max-width-padding=true] {
    ${Media.desktop`
      padding: 0 50px;
    `}
  }

  * {
    box-sizing: border-box;
    ${Mixin.font.Arial(14, 400)}
  }

  html {
    color: ${Theme.colors.black};
    line-height: 1.15;
  }

  html,
  body {
    margin: 0;
  }

  main {
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: button;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  b,
  strong {
    font-weight: bold;
  }

  li {
    list-style-type: none;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  @font-face {
    font-family: 'Factoria';
    font-display: swap;
    src:
      url(${FactoriaUltraTTF}) format('ttf'),
      url(${FactoriaUltraWOFF}) format('woff'),
      url(${FactoriaUltraWOFF2}) format('woff2');
  }

  /* HTML content block */
  .generic-list {
    margin: 0;
    padding: 10px 0;
    list-style: none;
    counter-reset: list;

    &[number] > li::before {
      content: counter(list);
    }

    & > li {
      padding: 3px 3px 3px 15px;
      margin-bottom: 10px;
      line-height: 20px;
      color: ${Theme.colors.black};

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        color: ${Theme.colors.dark_red};
        counter-increment: list;
        content: '•';
        float: left;
        margin-left: -15px;
      }
    }
  }

  .generic-list-title {
    margin-top: 21px;
    color: #1e1e1e;
    text-transform: uppercase;
    ${Mixin.font.Arial(14, 700)}
  }

  .generic-banner-headline {
    ${Mixin.font.ArialNarrow(16, 600)}
    color: #333;
    text-decoration: none;
    text-transform: uppercase;

    :hover {
      text-decoration: underline;
    }

    ::after {
      content: '';
      background-image: url(${ArrowIconFullRedRight});
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      height: 15px;
      width: 5px;
      margin-right: -3px;
      position: relative;
      top: 5px;
      left: 6px;
    }
  }

  .generic-button {
    display: inline-block;
    padding: 9px 23px 5px;
    background-color: #b02115;
    border: none;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    ${Mixin.font.ArialNarrow(12, 600)}

    + .generic-button {
      margin-left: 10px;
    }

    &.button-small {
      padding: 9px 12px 8px;
      font-size: 14px;
      line-height: 14px;
    }

    &:hover {
      background: #cc2c11;
      color: #fff;
    }
  }

  .tire-details-wrapper {
    margin: 20px 0 10px;
    line-height: 22px;
    max-height: 264px;
  }

  .tire-details-wrapper li {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .tire-details-wrapper p {
    margin: 0;
  }

  .generic-description {
    padding-bottom: 22px;
  }

  .tire-details-wrapper .generic-list {
    margin: 10px;
  }

  .tire-details-wrapper .read-more {
    color: #1262ac;
    font-weight: 600;
  }

  /* Print styles */
  [data-printonly] {
    display: none;
  }

  @media print {
    [data-printless] {
      display: none !important;
    }

    [data-printonly] {
      display: block;
    }

    html > body {
      height: auto !important;
    }
  }

  [data-html-block] {
    h1 {
      display: block;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #1e1e1e;
      ${Mixin.font.ArialBlack(24, 900)}
    }

    h2 {
      font-weight: 600;
    }

    .generic-headline {
      margin: 0 0 20px;
      letter-spacing: 0.5px;
      color: #1e1e1e;
      text-transform: uppercase;
      ${Mixin.font.ArialBlack(24, 900)}
    }

    @media (max-width: 1023px) {
      .generic-headline {
        margin: 0 0 10px;
        font-size: 30px;
        line-height: 43px;
      }

      .mobile-padding {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    a {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      color: ${Theme.colors.blue};
    }

    a:hover {
      text-decoration: none;
    }

    p {
      margin: 0 0 15px;
      font-size: 14px;
      line-height: 22px;
    }

    .responsive-table {
      width: 100%;
      overflow-x: auto;
    }

    table .align-top {
      vertical-align: top;
    }
  }

  .h1-title-alt {
    ${Mixin.font.ArialBlack()}
    line-height: 1.05em;
  }

  .teko-alt {
    ${Mixin.font.ArialNarrow()}
  }

  .open-sans-alt {
    ${Mixin.font.Arial()}
    line-height: 1.3em;
  }

  .cta-alt {
    ${Mixin.font.ArialNarrow()}
    letter-spacing: 0.05em;
  }

  .link-alt {
    ${Mixin.font.Arial()}
    letter-spacing: -0.01em;
    line-height: 1.3em;
  }

  ${Media.mobile`
    #smg-feedbackbtn {
      visibility: hidden !important;
    }

    #ae_launcher {
      visibility: hidden !important;
    }
  `}

  .ev-tires-tab-label::after {
    content: '';
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-image: url(${FilledDownArrow});
    background-repeat: no-repeat;
    text-align: center;
    position: absolute;
    right: 90px;
    transition: all 350ms;
  }

  ${Media.mobile`
    .ev-tires-tab-label::after {
      right: 20px;
    }
  `}

  .featured-ev-tires__tooltip-icon {
    background-size: 14px 14px;
    background-image: url(${IconInfoBlueRegular});
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .featured-ev-tires__tooltip-input:checked ~ .featured-ev-tires__tooltip-icon {
    background-image: url(${IconInfoBlueSolid});
  }
`

export default GlobalStyles
